import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";

const CustomDropdown = ({
  sliderWidth,
  min,
  max,
  step,
  type,
  label,
  color,
  onValueChange,
  valueMin,
  valueMax,
  minDrag,
  dType,
  scrollEnabled,
  setScrollEnabled
}) => {

  const handleValueChange = (value) => {
    onValueChange({ max: value });
    setOpen(false);
    setScrollEnabled(!scrollEnabled);
  };

  const [open, setOpen] = useState(false)

  const options = [
    { name: "1 Hour", value: 1 },
    { name: "2 Hours", value: 2 },
    { name: "3 Hours", value: 3 },
    { name: "4 Hours", value: 4 },
    { name: "5 Hours", value: 5 },
    { name: "6 Hours", value: 6 },
    { name: "7 Hours", value: 7 },
    { name: "8 Hours", value: 8 },
    { name: "9 Hours", value: 9 },
    { name: "10 Hours", value: 10 },
    { name: "11 Hours", value: 11 },
    { name: "12 Hours", value: 12 }
  ]

  const weeks = [
    { name: "1 Week", value: 1 },
    { name: "2 Weeks", value: 2 },
    { name: "3 Weeks", value: 3 },
    { name: "4 Weeks", value: 4 },
    { name: "5 Weeks", value: 5 },
    { name: "6 Weeks", value: 6 },
    { name: "7 Weeks", value: 7 },
    { name: "8 Weeks", value: 8 },
  ]

  const slots = [
    { name: "1 Slot", value: 1 },
    { name: "2 Slots", value: 2 },
    { name: "3 Slots", value: 3 },
    { name: "4 Slots", value: 4 },
    { name: "5 Slots", value: 5 },
    { name: "6 Slots", value: 6 },
    { name: "7 Slots", value: 7 },
    { name: "8 Slots", value: 8 },
  ]

  return (
    <View style={{}}>
      <TouchableOpacity
        onPress={() => {
          setOpen(open ? false : true);
          setScrollEnabled(!scrollEnabled);
        }}
        style={{
          marginTop: 5,
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          elevation: 1,
          height: 40,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0.5,
          borderColor: "#e6e6e6",
          paddingHorizontal: 10,
          justifyContent: "space-between",
          marginHorizontal: sliderWidth
        }}>
        {
          dType === "time" ?
            <Text style={{ color: color }}>
              Select Hour - {valueMax !== "" &&
                `${valueMax} ${valueMax >= 2 ? "Hour(s)" : "Hour"
                }`}
            </Text> :
            dType === "week" ?
              <Text style={{ color: color }}>
                Select Week - {valueMax !== "" &&
                  `${valueMax} ${valueMax >= 2 ? "week(s)" : "week"
                  }`}
              </Text> :
              dType === "slots" ?
                <Text style={{ color: color }}>
                  Select Slots - {valueMax !== "" &&
                    `${valueMax} ${valueMax >= 2 ? "Slots(s)" : "Slots"
                    }`}
                </Text> :
                <Text style={{ color: color }}>
                  Select
                </Text>
        }

        <MaterialIcons
          name={"keyboard-arrow-down"}
          size={24}
          color="#808080"
        />
      </TouchableOpacity>
      {
        open &&
        <FlatList
          style={{
            height: 200,
            paddingHorizontal: 10
          }}
          data={
            dType === "time" ?
              options :
              dType === "week" ?
                weeks :
                dType === "slots" ?
                  slots :
                  options
          }
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                key={index}
                style={styles.optionContainer}
                onPress={() => handleValueChange(item?.value)}
              >
                <Text style={styles.optionText}>{item?.name}</Text>
              </TouchableOpacity>
            );
          }}
          keyExtractor={(item, index) => index}
        />
      }
    </View >
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    borderRadius: 10,
    // width: "100%",
    // marginRight: 5,
    // paddingHorizontal: 10,
    // marginTop: 10,
  },
  selectedValueContainer: {
    height: 40,
    borderWidth: 1,
    borderColor: "#e6e6e6",
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  selectedValueText: {
    fontSize: 13,
    color: "#737373",
  },
  optionContainer: {
    height: 40,
    justifyContent: "center",
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  optionText: {
    fontSize: 12,
  },
});

export default CustomDropdown;
