import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  Modal,
  Dimensions
} from "react-native";
import CalendarPicker from "react-native-calendar-picker";
import React, { useState } from "react";
import { } from "react-native";
import { useEffect } from "react";
import Chart from "../Chart/Chart";
import { AntDesign } from "@expo/vector-icons";
import { locationSeparator } from "../../function/functionReturn";
import { getJobFacilityLocationDashboard, getJobFacilityLocationDashboardChart } from "../../api_url/jobTable";
import { useAuth } from "../../context/AuthContext";

const OrganizationJobsShift = ({ props }) => {
  const { authToken, userId, userUniqueId } = useAuth();

  const [facilityData, setFacilityData] = useState(undefined);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [dataProgressChart, setDataProgressChart] = useState(null);
  const [dataPieChart, setDataPieChart] = useState([]);

  const [openedJobs, setOpenedJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [pendingAssignment, setPendingAssignment] = useState([]);
  const [acceptedJobs, setAcceptedJobs] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [unFullFiled, setUnFullFiled] = useState([]);
  const [noShowJobs, setNoShowJobs] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [noShowJobsActive, setNoShowJobsActive] = useState([]);
  const [pendingJobsActive, setPendingJobsActive] = useState([]);
  const [pendingReview, setPendingReview] = useState([]);

  const [loading, setLoading] = useState(true);

  const [showCalendar, setShowCalendar] = useState(false);
  const [weekDays, setWeekDays] = useState(false);
  const { width, height } = Dimensions.get("window");

  useEffect(() => {
    getFacilityJob(userId);
    getChartData(userId, "Shift", new Date());
  }, [userId]);

  //Get Jobs List in Particular Facility
  const getFacilityJob = async (id) => {
    await getJobFacilityLocationDashboard(authToken, id, "Shift").then(
      res => {
        // console.log(res)
        setCompletedJobs(res?.completedJobs);
        setAcceptedJobs(res?.acceptedJobs);
        setUnFullFiled(res?.unFullFiled);
        setPendingAssignment(res?.pendingAssignment);
        setOpenedJobs(res?.openedJobs);
        setInProgress(res?.inProgress);
        setNoShowJobs(res?.noShowJobs);
        setNoShowJobsActive(res?.noShowJobsActive);
        setPendingJobs(res?.pendingJobs);
        setPendingJobsActive(res?.pendingJobsActive);
        setPendingReview(res?.pendingReview);
        setData(res?.totalJob);
        setFacilityData(res?.facility)

        setLoading(false);
      }
    ).catch(e => {
      console.log(e)
    })
  };

  const getChartData = async (id, type, startDate) => {
    await getJobFacilityLocationDashboardChart(authToken, id, type, startDate).then(
      res => {
        // console.log("Shift", res)
        setChartData(res?.chartData)
        setDataProgressChart(res?.dataProgressChart)
        setDataPieChart(res?.dataPieChart)
        setWeekDays(res?.weekDays)
        setShowCalendar(false)
      }
    ).catch(e => console.log(e))
  }

  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <Text style={{ fontWeight: "700" }}>Overall Task ({locationSeparator(facilityData?.location_id)})</Text>
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("ManageJobShift", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                marginBottom: 0,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 17,
                  fontWeight: "600",
                }}
              >
                Posted Shifts
              </Text>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "800",
                  color: "#2775BD",
                }}
              >
                {" "}
                {data}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginVertical: 10,
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("PendingReview", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                padding: 20,
                marginRight: 10,
                borderRadius: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 15, fontWeight: "600" }}>
                  Pending Approvals
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {pendingReview}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("PendingAssignment", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                padding: 20,
                borderRadius: 15,
                marginLeft: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 15, fontWeight: "600" }}>
                  Pending Assignment
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {pendingAssignment}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginVertical: 10,
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("NoShowShift", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                marginRight: 10,
                padding: 20,
                borderRadius: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    flex: 1,
                    fontSize: 15,
                    fontWeight: "600",
                  }}
                >
                  Missed Shifts
                </Text>
                {noShowJobsActive !== 0 && (
                  <View
                    style={{
                      marginLeft: 10,
                      backgroundColor: "#2775BD",
                      height: 25,
                      width: 25,
                      borderRadius: 100,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      {noShowJobsActive}
                    </Text>
                  </View>
                )}
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {noShowJobs}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("PendingJobs", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                marginLeft: 10,
                padding: 20,
                borderRadius: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    flex: 1,
                    fontSize: 15,
                    fontWeight: "600",
                  }}
                >
                  Pending Clock Out
                </Text>
                {pendingJobsActive !== 0 && (
                  <View
                    style={{
                      marginLeft: 10,
                      backgroundColor: "#2775BD",
                      height: 25,
                      width: 25,
                      borderRadius: 100,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      {pendingJobsActive}
                    </Text>
                  </View>
                )}
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {pendingJobs}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <Text style={{ fontWeight: "700" }}>{locationSeparator(facilityData?.location_id)} Dashboard</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginVertical: 10,
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("OpenJobs", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                padding: 20,
                borderRadius: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 15, fontWeight: "600" }}>
                  Open Shifts
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {openedJobs}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginVertical: 10,
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("AcceptedJobs", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                marginRight: 10,
                padding: 20,
                borderRadius: 15,
              }}

            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 15, fontWeight: "600" }}>
                  Accepted Shifts
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {acceptedJobs}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("InProgress", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                marginLeft: 10,
                padding: 20,
                borderRadius: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 15, fontWeight: "600" }}>
                  In-Progress
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {inProgress}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginVertical: 10,
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("CompletedJobs", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                marginRight: 10,
                padding: 20,
                borderRadius: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 15, fontWeight: "600" }}>
                  Completed Shifts
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {completedJobs}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => props?.navigation?.navigate("UnFullFiled", {
                data: {
                  from: "Location"
                },
              })}
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                marginLeft: 10,
                padding: 20,
                borderRadius: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 15, fontWeight: "600" }}>
                  Unfulfilled Shifts
                </Text>
              </View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 30,
                  fontWeight: "600",
                  color: "#2775BD",
                }}
              >
                {unFullFiled}
              </Text>
            </TouchableOpacity>
          </View>

          {dataProgressChart !== null &&
            <View>
              <TouchableOpacity
                onPress={() => setShowCalendar(true)}>
                <Text style={{
                  textAlign: "right",
                  paddingRight: 5,
                  fontWeight: "600"
                }}>
                  <AntDesign name="calendar" size={20} color="#000" />
                  {" "}Selected Week - <Text style={{ color: "#2775BD" }}>({weekDays[0] + " to " + weekDays[weekDays?.length - 1]})</Text>
                </Text>
              </TouchableOpacity>
              <Chart
                chartData={chartData}
                dataProgressChart={dataProgressChart}
                dataPieChart={dataPieChart}
                totalCount={data} />
            </View>
          }
        </ScrollView>
      )
      }
      <Modal
        animationType="fade"
        transparent={true}
        visible={showCalendar}
        onRequestClose={() => setShowCalendar(false)}
      >
        <View
          style={{
            height: height,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 20,
              justifyContent: "center",
              elevation: 5,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <CalendarPicker
              onDateChange={async (date) =>
                await getChartData(userId, "Shift", date)
              }
              selectedDayColor="#00b359"
              selectedDayTextColor="#FFFFFF"
              initialDate={new Date()}
            />
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => setShowCalendar(false)}
                style={{
                  backgroundColor: "#888",
                  marginHorizontal: 20,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View >
  );
};

export default OrganizationJobsShift;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});

