import { View, StyleSheet, Text, ScrollView, Platform, TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import UserCard from "./Card/UserCard";
import CustomInputSearch from "../../customComponents/Input/CustomInputSearch";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getManageNurseList, updateDetails } from "../../api_url/nurseTable";
import { useAuth } from "../../context/AuthContext";
import { getAllFacilityList, getFacilityDetails } from "../../api_url/facilityTable"
import { postFacilityNotification } from "../../api_url/facilityNotification"
import { socket } from "../../api_url/socket";
import { sendPushNotification, sendPushNotificationActivate } from "../../utils/notification";
import { sendNotification } from "../../utils/webNotification";

const ManageUser = (props) => {
  const { authToken, userId, userUniqueId } = useAuth();

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [user, setUser] = useState(undefined);

  const [dateSort, setDateSort] = useState(null);

  const onUserDetailNavigate = (d) => {
    props.navigation.navigate("UserDetailsScreen", {
      data: d,
    });
  };

  useEffect(() => {
    if (userId !== undefined) {
      getNurseList(userId);
    }
  }, [userId]);

  const getNurseList = async (id) => {
    try {
      const nurseList = await getManageNurseList(authToken, id);
      const facilityDetail = await getFacilityDetails(
        authToken, userId
      );
      setUser(facilityDetail)
      setData(nurseList.sort((a, b) =>
        a?.firstName
          ?.toLowerCase()
          ?.localeCompare(b?.firstName?.toLowerCase())
      ));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getFacilityLocationDetail = async (id) => {
    try {
      const facilityLocationData = await getAllFacilityList(authToken, id, "facility")
      // console.log(facilityLocationData)
      return facilityLocationData;
    } catch (error) {
      console.log("data save error:", error);
    }
  };

  const updateUser = async (id, data, name, value) => {
    await updateDetails(authToken, id, data).then(
      res => {
        getFacilityLocationDetail(userId)
          .then(async (facilitiesData) => {
            for (const facility of facilitiesData) {
              if (
                Number(userId) !== Number(facility?.id)
              ) {
                try {
                  await postFacilityNotification(
                    authToken,
                    facility?.id,
                    "",
                    `${name} has been ${value}d`,
                    `${name} has been ${value}d by ${user?.firstName} ${user?.lastName}`,
                    "ManageUser",
                    {
                      id: ""
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert",
                    {
                      table: "FacilityNotificationTable",
                      id: facility?.id,
                    });
                  socket?.emit("activateDeactivate",
                    {
                      table: "NurseTable",
                      id: id,
                    });
                  if (facility?.mobileId) {
                    if (Platform.OS === "web") {
                      sendNotification(authToken, {
                        expoPushToken: facility?.mobileId,
                        title: `${name} has been ${value}d`,
                        body: `${name} has been ${value}d by ${user?.firstName} ${user?.lastName}`,
                        data: { screen: "ManageUser" },
                      })
                    } else {
                      sendPushNotificationActivate({
                        expoPushToken: facility?.mobileId,
                        job:
                          `${name} has been ${value}d`,
                        name: name,
                        typeMessage: `${name} has been ${value}d by ${user?.firstName} ${user?.lastName}`,
                        jobId: "",
                      });
                    }
                  }
                  getNurseList(userId);
                } catch (error) {
                  console.error("Error fetching facility detail:", error);
                }
              }
            }
          })
          .catch((error) => console.error("Fetching facility:", error));
      }
    ).catch(e => console.log(e))
  };

  const updateUserSuspend = async (id, data, name, value) => {
    await updateDetails(authToken, id, data).then(
      res => {
        getFacilityLocationDetail(userId)
          .then(async (facilitiesData) => {
            for (const facility of facilitiesData) {
              if (
                Number(userId) !== Number(facility?.id)
              ) {
                try {
                  await postFacilityNotification(
                    authToken,
                    facility?.id,
                    "",
                    `${name} has been ${value}ed`,
                    `${name} has been ${value}ed by ${user?.firstName} ${user?.lastName}`,
                    "ManageUser",
                    {
                      id: "",
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert",
                    {
                      table: "FacilityNotificationTable",
                      id: facility?.id,
                    })
                  socket?.emit("activateDeactivate",
                    {
                      table: "NurseTable",
                      id: id,
                    });
                  if (facility?.mobileId) {
                    if (Platform.OS === "web") {
                      sendNotification(authToken, {
                        expoPushToken: facility?.mobileId,
                        title: `${name} has been ${value}ed`,
                        body: `${name} has been ${value}ed by ${user?.firstName} ${user?.lastName}`,
                        data: { screen: "ManageUser" },
                      })
                    } else {
                      sendPushNotificationActivate({
                        expoPushToken: facility?.mobileId,
                        job:
                          `${name} has been ${value}ed`,
                        name: name,
                        typeMessage: `${name} has been ${value}ed by ${user?.firstName} ${user?.lastName}`,
                        jobId: "",
                      });
                    }
                  }
                  getNurseList(userId);
                } catch (error) {
                  console.error("Error fetching facility detail:", error);
                }
              }
            }
          })
          .catch((error) => console.error(error));
      }
    ).catch(e => console.log(e))
  };

  const onChatRoomNavigate = (d) => {
    props?.navigation.navigate("ChatRoomScreen", {
      data: d,
    });
  };

  const handleChange = (e) => {
    setSearchText(e);
  };
  const handleChangeClear = () => {
    setSearchText("");
  };

  const filter = (data) => {
    return data?.filter(
      (item) =>
        item?.firstName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.lastName?.toLowerCase().includes(searchText.toLowerCase())
      //  ||
      // item?.licenseType?.toLowerCase().includes(searchText?.toLowerCase())
    );
  };

  if (dateSort) {
    data?.sort((a, b) => b?.firstName.localeCompare(a?.firstName));
  } else {
    data?.sort((a, b) => a?.firstName.localeCompare(b?.firstName));
  }


  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              paddingBottom: 5,
              marginHorizontal: 5,
              alignItems: "center",
              marginTop: 5,
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <CustomInputSearch
              value={searchText}
              placeholder="Search first or last name. . ."
              keyboardType={"web-search"}
              onChangeText={(text) => handleChange(text)}
              clearValue={() => handleChangeClear()}
            />
            <TouchableOpacity onPress={() => setDateSort(!dateSort)} style={{ marginLeft: 5 }}>
              <MaterialCommunityIcons name="sort" size={22} color="#595959" />
            </TouchableOpacity>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: Platform.OS === "ios" ? 30 : 50,
            }}
          >
            {filter(data)?.map((element, index) => {
              return (
                <UserCard
                  key={element?.id}
                  element={element}
                  updateUser={updateUser}
                  onUserDetailNavigate={onUserDetailNavigate}
                  onChatRoomNavigate={onChatRoomNavigate}
                  userId={userId}
                  userUniqueId={userUniqueId}
                  updateUserSuspend={updateUserSuspend}
                />
              );
            })}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default ManageUser;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
