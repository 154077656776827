import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
// import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
//Icons
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
// Style
import { GlobalStyles } from "../utils/GlobalStyles";
//Screens
import AnnouncementScreen from "../screens/Manage/Announcement";
import TimeOffScreen from "../screens/More/TimeOff/TimeOffScreen";
import TimeOffScreenNurse from "../screens/More/TimeOff/TimeOffScreenNurse";
import UserDetailsScreen from "../screens/User/UserDetails";
import JobDetailsScreen from "../screens/JobDetails/JobDetailsScreen";
import TemplateDetailsScreen from "../screens/JobDetails/TemplateDetailsScreen";
import AddTimeOffScreen from "../screens/More/TimeOff/TimeOff";
import RequestTimeOffScreen from "../screens/More/TimeOff/TimeOffRequest";
import MoreScreen from "../screens/More/MoreScreen";
import HomeScreen from "../screens/Home/HomeScreen";
import CalendarScreen from "../screens/Calendar/CalendarScreen";
import OrganizationJob from "../screens/OrganizationJob/OrganizationJobView";
import ChatScreen from "../screens/Chat/ChatScreen";
import ChatRoomScreen from "../screens/Chat/ChatRoomScreen";
import NotificationScreen from "../screens/Notification/NotificationScreen";
import ManageTemplate from "../screens/Manage/ManageTemplate";
import ManageJob from "../screens/Manage/ManageJob";
import ManagePTO from "../screens/Manage/ManagePTO";
import ManageUser from "../screens/Manage/ManageUser";
import ProfileScreen from "../screens/More/Profile";
import ContactScreen from "../screens/More/Contact";
import PrivacyPolicyScreen from "../screens/More/PrivacyPolicy";
import TermsAndConditionsScreen from "../screens/More/TermsAndConditions";
import SettingScreen from "../screens/More/Setting";
import ManagePage from "../screens/Manage/ManagePage"
import AddJobScreen from "../screens/Manage/AddJobScreen"
import EditJobScreen from "../screens/Manage/EditJobScreen";
import EditTemplateScreen from "../screens/Manage/EditTemplateScreen";
import CompletedJobs from "../screens/Jobs/CompletedJobs";
import AcceptedJobs from "../screens/Jobs/AcceptedJobs";
import PendingAssignment from "../screens/Jobs/PendingAssignment";
import OpenJobs from "../screens/Jobs/OpenJobs";
import InProgress from "../screens/Jobs/InProgress";
import UnFullFiled from "../screens/Jobs/UnFullFiled";
import PendingJobs from "../screens/Jobs/PendingJobs";
import PendingReview from "../screens/Jobs/PendingReview";
import ManageJobShift from "../screens/Jobs/ManageJobShift";
import NoShowShift from "../screens/Jobs/NoShowShift";
import CompletedJobsVisit from "../screens/Jobs/Visit/CompletedJobsVisit";
import AcceptedJobsVisit from "../screens/Jobs/Visit/AcceptedJobsVisit";
import PendingAssignmentVisit from "../screens/Jobs/Visit/PendingAssignmentVisit";
import OpenJobsVisit from "../screens/Jobs/Visit/OpenJobsVisit";
import InProgressVisit from "../screens/Jobs/Visit/InProgressVisit";
import UnFullFiledVisit from "../screens/Jobs/Visit/UnFullFiledVisit";
import ManageJobVisit from "../screens/Jobs/Visit/ManageJobVisit";
import NoShowVisit from "../screens/Jobs/Visit/NoShowVisit";
import PendingJobsVisit from "../screens/Jobs/Visit/PendingJobsVisit";
import PendingReviewVisit from "../screens/Jobs/Visit/PendingReviewVisit";
import AppInfo from "../screens/More/AppInfo";
import SettingNotification from "../screens/More/Setting/Notification";
import SettingChangePassword from "../screens/More/Setting/ChangePassword";
import ContactContactUs from "../screens/More/Contact/ContactUs";
import ContactTechnicalSupport from "../screens/More/Contact/TechnicalSupport";
import MyPaymentsScreen from "../screens/More/MyPayments/MyPayments";
import EditProfileForm from "../screens/More/Profile/EditProfileForm";
import OnDayCalenderView from "../screens/Calendar/OnDayCalenderView";
import { Octicons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import { Platform } from "react-native";
import ChatProvider from "../context/ChatContext";

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const MoreScreenStack = (props) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="MoreScreen">
        {(props) => <MoreScreen {...props} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

const OrganizationJobScreenStack = (props) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="OrganizationJob">
        {(props) => <OrganizationJob {...props} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

const CalendarScreenStack = (props) => {
  let userId = props?.userId;
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
      }}
    >
      <Stack.Screen name="CalendarScreen">
        {(props) => <CalendarScreen {...props} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};


const HomeScreenStack = (props) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
      }}
    >
      <Stack.Screen name="HomeScreen">
        {(props) => <HomeScreen {...props} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

const colorFacility = "#00b359";

//Bottom Tab Navigator for both iOS & Android
const TabNavigator = (props) => {
  return (
    <>
      <Tab.Navigator
        initialRouteName="Home"
        screenOptions={{
          tabBarShowLabel: false,
          tabBarHideOnKeyboard: true,
          tabBarStyle: {
            height: 60,
            elevation: 0,
          },
        }}
      >
        <Tab.Screen
          name="Home"
          options={{
            headerShown: false,
            tabBarIcon: ({ color, focused }) => (
              <View
                style={{
                  marginTop: Platform.OS === "ios" ? 30 : 0,
                  height: 60,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Octicons
                  name="home"
                  size={focused ? 28 : 24}
                  color={focused ? "#000" : "#808080"}
                />
              </View>
            ),
          }}
        >
          {(props) => <HomeScreenStack {...props} />}
        </Tab.Screen>
        <Tab.Screen
          name="Calendar"
          options={{
            headerShown: false,
            tabBarIcon: ({ color, focused }) => (
              <View
                style={{
                  marginTop: Platform.OS === "ios" ? 30 : 0,
                  height: 60,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Ionicons
                  name="calendar-outline"
                  color={focused ? "#000" : "#808080"}
                  size={focused ? 28 : 24}
                />
              </View>
            ),
          }}
        >
          {(props) => <CalendarScreenStack {...props} />}
        </Tab.Screen>
        <Tab.Screen name="ManagePage"
          component={ManagePage}
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#FFF",
              borderBottomWidth: 0,
            },
            headerTitleAlign: "center",
            headerLeft: () => (
              <TouchableOpacity
                onPress={() => props?.navigation.navigate("Home")}>
                <Image
                  source={require("../../assets/login/NURSD-Flow.png")}
                  style={{
                    marginLeft: 0,
                    width: 82,
                    marginBottom: 0,
                    height: 32,
                  }}
                />
              </TouchableOpacity>
            ),
            headerTitle: (props) => (
              <View style={GlobalStyles.headerTitleView}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Manage
                </Text>
              </View>
            ),
            tabBarIcon: ({ color, focused }) => (
              <View
                style={{
                  marginTop: Platform.OS === "ios" ? 30 : 0,
                  height: 35,
                  width: 35,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colorFacility,
                  borderRadius: 100,
                }}
              >
                <MaterialIcons name="add" size={30} color={"#fff"} />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="Location"
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#FFF",
              borderBottomWidth: 0,
            },
            headerTitleAlign: "center",
            headerLeft: () => (
              <TouchableOpacity
                onPress={() => props?.navigation.navigate("Home")}>
                <Image
                  source={require("../../assets/login/NURSD-Flow.png")}
                  style={{
                    marginLeft: 0,
                    width: 82,
                    marginBottom: 0,
                    height: 32,
                  }}
                />
              </TouchableOpacity>
            ),
            headerTitle: (props) => (
              <View style={GlobalStyles.headerTitleView}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Location Hub
                </Text>
              </View>
            ),
            tabBarIcon: ({ color, focused }) => (
              <View
                style={{
                  marginTop: Platform.OS === "ios" ? 30 : 0,
                  height: 60,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Ionicons name="md-newspaper-outline"
                  size={focused ? 28 : 24}
                  color={focused ? "#000" : "#808080"} />
              </View>
            ),
          }}
        >
          {(props) => <OrganizationJobScreenStack {...props} />}
        </Tab.Screen>
        <Tab.Screen
          name="More"
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#FFF",
              borderBottomWidth: 0,
            },
            headerTitleAlign: "center",
            headerLeft: () => (
              <TouchableOpacity
                onPress={() => props?.navigation.navigate("Home")}>
                <Image
                  source={require("../../assets/login/NURSD-Flow.png")}
                  style={{
                    marginLeft: 0,
                    width: 82,
                    marginBottom: 0,
                    height: 32,
                  }}
                />
              </TouchableOpacity>
            ),
            headerTitle: (props) => (
              <View style={GlobalStyles.headerTitleView}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Menu
                </Text>
              </View>
            ),
            tabBarIcon: ({ color, focused }) => (
              <View
                style={{
                  marginTop: Platform.OS === "ios" ? 30 : 0,
                  height: 60,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Entypo
                  name="menu"
                  size={focused ? 28 : 24}
                  color={focused ? "#000" : "#808080"}
                />
              </View>
            ),
          }}
        >
          {(props) => <MoreScreenStack {...props} />}
        </Tab.Screen>
      </Tab.Navigator>
    </>
  );
};

//Other Screen Navigator
const MainNavigator = (props) => {

  return (
    <Stack.Navigator
      screenOptions={{
        headerTitleStyle: {
          fontSize: 14,
          textAlign: "center",
          alignSelf: "center",
          color: "white",
        },
        headerStyle: {
          backgroundColor: "#4a7bd0",
        },
        headerRight: () => <View />,
      }}
    >
      <Stack.Screen name="Nav" options={{ headerShown: false }}>
        {(props) => <TabNavigator {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ChatScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Chat History
              </Text>
            </View>
          ),
          tabBarIcon: ({ color, focused }) => (
            <View
              style={{
                marginTop: Platform.OS === "ios" ? 30 : 0,
                height: 60,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Ionicons
                name="chatbubble-outline"
                color={focused ? "#000" : "#808080"}
                size={focused ? 28 : 24}
              />
            </View>
          ),
        }}>
        {(props) => <ChatScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ProfileScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Profile
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ProfileScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="NotificationScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Notifications
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <NotificationScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ChatRoomScreen"
      >
        {(props) => <ChatRoomScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="AddJobScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Create Job
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <AddJobScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ManageJob"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Manage Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ManageJob  {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ManageUser"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Manage Employees
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ManageUser {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ManageTemplate"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Manage Templates
              </Text>
            </View>
          ),
          headerRight: () => (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#00b359",
                  paddingVertical: 5,
                  paddingHorizontal: 8,
                  borderRadius: 5,
                  marginHorizontal: 10
                }}
                activeOpacity={0.5}
                onPress={() =>
                  props?.navigation.navigate("AddJobScreen")
                }
              >
                <Text
                  style={{ fontWeight: "500", fontSize: 12, color: "#fff" }}
                >
                  Create
                </Text>
              </TouchableOpacity>
            </View>
          ),
        }}
      >
        {(props) => <ManageTemplate {...props} />}
      </Stack.Screen>

      <Stack.Screen
        name="ManagePTO"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Manage PTO
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ManagePTO {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="TermsAndConditionsScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Terms & Conditions
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <TermsAndConditionsScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="PrivacyPolicyScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Privacy Policy
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <PrivacyPolicyScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="SettingScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Settings
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <SettingScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ContactScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Contact
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ContactScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ManageJobShift"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Manage Shift Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ManageJobShift {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="NoShowShift"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Missed Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <NoShowShift {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="CompletedJobs"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Completed Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <CompletedJobs {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="AcceptedJobs"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Assigned Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <AcceptedJobs {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="PendingAssignment"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Pending Assignment
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <PendingAssignment {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="OpenJobs"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Open Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <OpenJobs {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="InProgress"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                In-Progress
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <InProgress {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="UnFullFiled"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Unfulfilled Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <UnFullFiled {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="PendingJobs"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Pending Clock Out
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <PendingJobs {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="PendingReview"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Pending Approvals
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <PendingReview {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ManageJobVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Manage Visit Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ManageJobVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="CompletedJobsVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Completed Job
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <CompletedJobsVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="AcceptedJobsVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Assigned Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <AcceptedJobsVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="NoShowVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Missed Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <NoShowVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="PendingJobsVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Pending Clock Out
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <PendingJobsVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="PendingReviewVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Pending Approvals
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <PendingReviewVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="PendingAssignmentVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Pending Assignments
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <PendingAssignmentVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="OpenJobsVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Open Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <OpenJobsVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="InProgressVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                In-Progress
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <InProgressVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="UnFullFiledVisit"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Unfulfilled Jobs
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <UnFullFiledVisit {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="AppInfo"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                App Info
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <AppInfo {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="SettingNotification"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Notifications
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <SettingNotification {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="SettingChangePassword"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Change Password
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <SettingChangePassword {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ContactContactUs"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Contact Us
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ContactContactUs {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="ContactTechnicalSupport"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Technical Support
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <ContactTechnicalSupport {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="MyPaymentsScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                My Payments
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <MyPaymentsScreen {...props} />}
      </Stack.Screen>

      <Stack.Screen
        name="TimeOffScreenNurse"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Time Off Details
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <TimeOffScreenNurse {...props} />}
      </Stack.Screen>

      <Stack.Screen
        name="AddTimeOffScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Time Off
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <AddTimeOffScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="RequestTimeOffScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Time Off Request
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <RequestTimeOffScreen {...props} />}
      </Stack.Screen>

      <Stack.Screen
        name="TimeOffScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Time Off
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <TimeOffScreen {...props} />}
      </Stack.Screen>

      <Stack.Screen
        name="JobDetailsScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Job Details
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <JobDetailsScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="UserDetailsScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                User Details
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <UserDetailsScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="TemplateDetailsScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Template Details
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <TemplateDetailsScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="EditJobScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Edit Job
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <EditJobScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="EditTemplateScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Edit Template
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <EditTemplateScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="AnnouncementScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Announcements
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <AnnouncementScreen {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="EditProfileForm"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Edit Details
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <EditProfileForm {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="OnDayCalenderViewScreen"
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#FFF",
            borderBottomWidth: 0,
          },
          headerTitleAlign: "center",
          headerLeft: () => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={() => props.navigation.goBack()}
              activeOpacity={0.5}
            >
              <MaterialIcons
                name="keyboard-arrow-left"
                size={28}
                color="#808080"
              />

              <Image
                source={require("../../assets/login/NURSD-Flow.png")}
                style={{
                  marginLeft: 0,
                  width: 82,
                  marginBottom: 0,
                  height: 32,
                }}
              />
            </TouchableOpacity>
          ),
          headerTitle: (props) => (
            <View style={GlobalStyles.headerTitleView}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Day View
              </Text>
            </View>
          ),
        }}
      >
        {(props) => <OnDayCalenderView {...props} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

//ios
const Navigator = () => {
  return (
    <Stack.Navigator initialRouteName="Main">
      <Stack.Screen name="Main" options={{ headerShown: false }}>
        {(props) => <MainNavigator {...props} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

const AppContainer = () => {
  return (
    <NavigationContainer>
      <ChatProvider>
        <Navigator />
      </ChatProvider>
    </NavigationContainer>
  );
};

export default AppContainer;
