import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  Platform,
  Dimensions,
  TouchableOpacity,
  Modal,
} from "react-native";
import React, { useState, useEffect } from "react";
import MyPayCard from "../Card/MyPayCard";
import moment from "moment";
import {
  DateFormat,
  jobUniqueId,
  timeDifferent,
} from "../../../utils/function";
import { getJobTypeList } from "../../../api_url/jobTable";
import { convertToCSV, downloadCSV } from "../../../function/functionReturn";
import { getNurseDetails } from "../../../api_url/nurseTable";
import { useAuth } from "../../../context/AuthContext";
import CalendarPicker from "react-native-calendar-picker";

const Visit = ({ props }) => {
  const { width, height } = Dimensions.get("window");

  const { authToken, userId, userUniqueId, removeAuthToken } = useAuth();

  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);
  const [showCalendarDate, setShowCalendarDate] = useState("");
  const [showCalendarEndDate, setShowCalendarEndDate] = useState("");

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [end, setEnd] = useState(24);
  const [filterDataOG, setFilterDataOG] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterCSVData, setFilterCSVData] = useState([]);

  useEffect(() => {
    if (userId !== undefined) {
      getFacilityJob(userId);
    }
  }, [userId]);

  async function returnNurseName(id) {
    const itemArr = await getNurseDetails(authToken, id);
    return itemArr?.firstName + itemArr?.lastName;
  }

  //Get Jobs List in Particular Facility
  const getFacilityJob = async (id) => {
    setLoading(true);
    const itemArr = await getJobTypeList(authToken, id, "Visit")
    setData(
      itemArr
        .sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate))
    );
    setFilterData(
      itemArr
        .sort((a, b) => new Date(b?.startDate) - new Date(a?.startDate))
    );

    const results = await Promise.all(
      itemArr
        .sort((a, b) => new Date(b?.startDate) - new Date(a?.startDate))
        .map(async (item) => {
          const {
            shiftTitle,
            // total_payment,
            createdAt,
            jobType,
            worked_hours,
            startDate,
            endDate,
            startTime,
            endTime,
            // baseRate,
            fullAddress,
            jobFinalSelectionNurseId,
          } = item;

          const nurseName = await returnNurseName(jobFinalSelectionNurseId);

          return {
            JobId: jobUniqueId(createdAt, jobType),
            NurseName: nurseName,
            VisitTitle: shiftTitle,
            // BaseRate: "$" + baseRate,
            // TotalPayment: total_payment,
            ScheduledHours: timeDifferent(startTime, endTime),
            ActualHours: worked_hours,
            StartDate: moment(new Date(startDate)).format("MM-DD-YYYY"),
            EndDate: moment(new Date(endDate)).format("MM-DD-YYYY"),
            StartTime: moment(new Date(startTime)).format("hh:mm a"),
            EndTime: moment(new Date(endTime)).format("hh:mm a"),
            FullAddress: fullAddress,
          };
        })
    );

    const filteredResults = results.filter(Boolean);

    setFilterCSVData(filteredResults);

    setEnd(
      itemArr
        .sort((a, b) => new Date(b?.startDate) - new Date(a?.startDate))
        .length - 1
    );

    setLoading(false);
  };

  const onJobDetailNavigate = (d) => {
    props.navigation.navigate("JobDetailsScreen", {
      data: d,
    });
  };

  const filterDataSet = async (min, max) => {
    const filteredData = data?.filter((value, index) => {
      return moment(value?.startDate)?.isSameOrAfter(min) &&
        moment(value?.startDate)?.isSameOrBefore(max);
    });

    setFilterData(filteredData);

    const results = await Promise.all(
      data
        .filter((value, index) => {
          return index >= min && index <= max;
        })
        .map(async (item) => {
          const {
            shiftTitle,
            // total_payment,
            createdAt,
            jobType,
            worked_hours,
            startDate,
            endDate,
            startTime,
            endTime,
            // baseRate,
            fullAddress,
            jobFinalSelectionNurseId,
          } = item;

          const nurseName = await returnNurseName(jobFinalSelectionNurseId);

          return {
            JobId: jobUniqueId(createdAt, jobType),
            NurseName: nurseName,
            VisitTitle: shiftTitle,
            // BaseRate: "$" + baseRate,
            // TotalPayment: total_payment,
            ScheduledHours: timeDifferent(startTime, endTime),
            ActualHours: worked_hours,
            StartDate: moment(new Date(startDate)).format("MM-DD-YYYY"),
            EndDate: moment(new Date(endDate)).format("MM-DD-YYYY"),
            StartTime: moment(new Date(startTime)).format("hh:mm a"),
            EndTime: moment(new Date(endTime)).format("hh:mm a"),
            FullAddress: fullAddress,
          };
        })
    );

    const filteredResults = results.filter(Boolean);

    setFilterCSVData(filteredResults);
  };

  const disabledDatesTextStyle = { color: "#CCCCCC" };

  const isPastDate = (date) => {
    return date.isBefore(data?.sort(
      (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
    )[data.length - 1]?.startDate, "day")
      ||
      date.isAfter(data?.sort(
        (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
      )[0]?.startDate, "day")
      ;
  };

  const disabledDates = (date) => {
    return isPastDate(date);
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : data?.length === 0 ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>No data</Text>
        </View>
      ) : (
        <View>
          {data.length > 1 && (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  top: 5,
                  marginHorizontal: 10,
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: "600" }}>
                  Report Date Range = {moment(
                    data?.sort(
                      (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                    )[data?.length - 1]?.startDate
                  ).format("MMM DD/YYYY")} - {moment(
                    data?.sort(
                      (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                    )[0]?.startDate
                  ).format("MMM DD/YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  marginHorizontal: 25,
                  marginVertical: 10,
                }}
              >
                <TouchableOpacity
                  style={{
                    backgroundColor: "#ccc",
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderRadius: 3,
                  }}
                  onPress={() => setShowCalendar(true)}
                >
                  <Text style={{ color: "#000" }}>Start Date -
                    {" "}{showCalendarDate === "" ? DateFormat(
                      data?.sort(
                        (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                      )[data?.length - 1]?.startDate
                    )
                      : DateFormat(showCalendarDate)}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#ccc",
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderRadius: 3,
                  }}
                  onPress={() => setShowCalendarEnd(true)}
                >
                  <Text style={{ color: "#000" }}>End Date -
                    {" "}{showCalendarEndDate === "" ? DateFormat(
                      data?.sort(
                        (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                      )[0]?.startDate
                    ) : DateFormat(showCalendarEndDate)}</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
          {/* {data.length > 1 && (
            <View style={{ marginBottom: 15, marginTop: -2 }}>
              <CustomSliderFilter
                sliderWidth={width / 1.6}
                min={0}
                max={end === 0 ? 1 : parseInt(end)}
                step={1}
                type={""}
                label={false}
                color={"#00b359"}
                onValueChange={(range) => {
                  let { min, max } = range;
                  filterDataSet(min, max);
                }}
                valueMin={0}
                valueMax={end === 0 ? 1 : parseInt(end)}
                minDrag={true}
              />
            </View>
          )} */}
          <ScrollView showsVerticalScrollIndicator={false}>
            {data?.length === 0 ? (
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    marginTop: 10
                  }}>No Jobs</Text>
              </View>
            ) : (
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: Platform.OS === "ios" ? 60 : 90,
                }}
              >
                {/* <Text>{data?.length}</Text> */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    marginHorizontal: 25,
                    marginBottom: 10,
                    marginTop: data.length > 1 ? 0 : 10
                  }}
                >
                  <TouchableOpacity
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 5,
                      paddingVertical: 3,
                      borderRadius: 3,
                    }}
                    onPress={() => downloadCSV(convertToCSV(filterCSVData), "Visit")}
                  >
                    <Text style={{ color: "#fff" }}>Download</Text>
                  </TouchableOpacity>
                </View>

                {filterData
                  ?.sort(
                    (a, b) => new Date(b.startDate) - new Date(a.startDate)
                  )
                  .map((element, index) => {
                    return (
                      <MyPayCard
                        key={element?.id}
                        element={element}
                        onJobDetailNavigate={onJobDetailNavigate}
                        userId={userId}
                        authToken={authToken}
                      />
                    );
                  })}
              </View>
            )}
          </ScrollView>
        </View>
      )}
      <Modal
        animationType="fade"
        transparent={true}
        visible={showCalendar}
        onRequestClose={() => setShowCalendar(false)}
      >
        <View
          style={{
            height: height,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 20,
              justifyContent: "center",
              elevation: 5,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <CalendarPicker
              onDateChange={(date) => {
                filterDataSet(moment(date).format("YYYY-MM-DD"),
                  showCalendarEndDate === "" ?
                    data?.sort(
                      (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                    )[0]?.startDate
                    : showCalendarEndDate
                )
                setShowCalendarDate(moment(date).format("YYYY-MM-DD"));
                setShowCalendar(false);
              }}
              selectedDayColor="#00b359"
              selectedDayTextColor="#FFFFFF"
              initialDate={showCalendarDate === "" ?
                data?.sort(
                  (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                )[data?.length - 1]?.startDate

                : showCalendarDate}
              disabledDates={disabledDates}
              disabledDatesTextStyle={disabledDatesTextStyle}
            />
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => setShowCalendar(false)}
                style={{
                  backgroundColor: "#888",
                  marginHorizontal: 20,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showCalendarEnd}
        onRequestClose={() => setShowCalendarEnd(false)}
      >
        <View
          style={{
            height: height,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 20,
              justifyContent: "center",
              elevation: 5,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <CalendarPicker
              onDateChange={(date) => {
                filterDataSet(
                  showCalendarDate === "" ?
                    data?.sort(
                      (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                    )[data?.length - 1]?.startDate

                    : showCalendarDate,
                  moment(date).format("YYYY-MM-DD")
                )
                setShowCalendarEndDate(moment(date).format("YYYY-MM-DD"));
                setShowCalendarEnd(false);
              }}
              selectedDayColor="#00b359"
              selectedDayTextColor="#FFFFFF"
              initialDate={showCalendarEndDate === "" ?
                data?.sort(
                  (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
                )[0]?.startDate
                : showCalendarEndDate}
              disabledDates={disabledDates}
              disabledDatesTextStyle={disabledDatesTextStyle}
            />
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => setShowCalendarEnd(false)}
                style={{
                  backgroundColor: "#888",
                  marginHorizontal: 20,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default Visit;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
