import {
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import React, { useState, } from "react";
import ShiftScreen from "./OrganizationJobsShift";
import VisitScreen from "./OrganizationJobsVisit";

const OrganizationJobView = (props) => {
  const [jobType, setJobType] = useState("shift");
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginTop: 10,
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: jobType === "shift" ? "#000" : "#fff",
              width: "40%",
              alignItems: "center",
              paddingVertical: 5,
              borderRadius: 5,
              borderWidth: 0.5,
            }}
            activeOpacity={0.5}
            onPress={() => setJobType("shift")}
          >
            <Text style={{ color: jobType === "shift" ? "#fff" : "#000" }}>
              Shift Jobs{" "}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              backgroundColor: jobType === "visit" ? "#000" : "#fff",
              width: "40%",
              alignItems: "center",
              paddingVertical: 5,
              borderRadius: 5,
              borderWidth: 0.5,
            }}
            activeOpacity={0.5}
            onPress={() => setJobType("visit")}
          >
            <Text style={{ color: jobType === "visit" ? "#fff" : "#000" }}>
              Visit Jobs{" "}
            </Text>
          </TouchableOpacity>
        </View>
        {jobType === "shift" ? (
          <ShiftScreen props={props} />
        ) : (
          <VisitScreen props={props} />
        )}
      </View>

    </View>
  );
};

export default OrganizationJobView;
