import { API_URL } from "./config";

export async function getAccessKey(authToken, type, id) {
  if (!authToken) {
    return;
  }
  // console.log(`${API_URL}/access-token/${type}/${id}`)
  const res = await fetch(`${API_URL}/access-token/${type}/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}
