import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import React, { useState } from "react";
import ChatScreenNurseCard from "./Card/ChatScreenNurseCard";
import { useEffect } from "react";
import { getFacilityChatRooms, getLastMessage, updateLastMessage } from "../../api_url/chatHistoryTable";
import { useAuth } from "../../context/AuthContext";
import { getAllNurseList } from "../../api_url/nurseTable";
import { ChatState } from "../../context/ChatContext";
import { socket } from "../../api_url/socket";

const ChatScreen = (props) => {

  const onChatRoomNavigate = (d) => {
    props.navigation.navigate("ChatRoomScreen", {
      data: d,
    });
  };
  const { authToken, userId, userUniqueId } = useAuth();
  const {
    chats,
    setChats,
    fetchAgain,
    dashboardChatIconFetchAgain,
    setDashboardChatIconFetchAgain } = ChatState();

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    socket?.on("chatRoomInsertedEvent", (data) => {
      if (data?.chatHistoryTableNurseTableId === userUniqueId) {
        // console.log("chatRoomInsertedEvent", data);
        getChatRoomsFacility(authToken, userUniqueId);
      }
    });
  }, [socket, userUniqueId]);

  useEffect(() => {
    if (userId !== undefined) {
      getChatRoomsFacility(authToken, userUniqueId);
    }
  }, [userId, fetchAgain]);

  useEffect(() => {
    if (userId !== undefined) {
      getNurse(authToken, userId);
    }
  }, [userId]);

  const getNurse = async (authToken, userId) => {
    try {
      const list = await getAllNurseList(authToken, userId)
      // console.log(list)
      setUserList(list)
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getChatRoomsFacility = async (authToken, userId) => {
    try {
      const chatRoomData = await getFacilityChatRooms(authToken, userId)
      // console.log(chatRoomData)
      setChats(chatRoomData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Text style={{ marginTop: 20, fontSize: 12 }}>Loading...</Text>
          ) :
            <View style={{ flex: 1 }}>
              {chats !== undefined &&
                (chats?.length > 0 ? (
                  chats.map((element, index) => {
                    return (
                      <ChatScreenNurseCard
                        key={index}
                        userId={userId}
                        userUniqueId={userUniqueId}
                        authToken={authToken}
                        element={element}
                        onChatRoomNavigate={onChatRoomNavigate}
                      />
                    );
                  })
                ) : (
                  <Text style={{ marginTop: 20, fontSize: 12 }}>
                    No Message List
                  </Text>
                ))}

              {/* {userList !== undefined &&
                (userList?.length > 0 ? (
                  userList.map((element, index) => {
                    return (
                      <View
                        style={{ flex: 1 }}
                        key={index}>
                        <TouchableOpacity
                          activeOpacity={0.5}
                        onPress={() =>
                          onUserDetailNavigate({
                            id: element?.id,
                            facilityId: userId,
                          })
                        }
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 10,
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                borderWidth: 1,
                                borderRadius: 10,
                                borderColor: "#2775BD",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                width: 45,
                                height: 45,
                                overflow: "hidden",
                              }}
                            >
                              <Image
                                source={require("../../../assets/images/icon.jpg")}
                                style={{ width: 80, height: 80 }}
                              />

                            </View>
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flex: 1,
                              }}
                            >
                              <View
                                style={{
                                  marginLeft: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <View>
                                  <Text>
                                    {element?.firstName} {element?.lastName}
                                  </Text>
                                  <Text
                                    style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 12 }}
                                  >
                                    {element?.primaryLicenseType}
                                  </Text>
                                </View>
                                <TouchableOpacity
                                  onPress={() =>
                                    onChatRoomNavigate({
                                      chatRoomId: undefined,
                                      nurseId: element?.uniqueId,
                                      facilityId: userUniqueId,
                                    })
                                  }
                                  style={{
                                    backgroundColor: "#ddd",
                                    width: 80,
                                    height: 25,
                                    borderRadius: 5,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text style={{ color: "#000", fontWeight: "600", fontSize: 12 }}>
                                    Message
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>

                          <View
                            style={{
                              borderWidth: 1,
                              borderStyle: "dashed",
                              borderColor: "lightgray",
                              marginHorizontal: 5,
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    );
                  })
                ) : (
                  <Text style={{ marginTop: 20, fontSize: 12 }}>
                    No Message List
                  </Text>
                ))} */}
            </View>
          }
        </View>
      </ScrollView>
    </View>
  );
};

export default ChatScreen;
