import { API_URL } from "./config";

export async function getNurseAllTimeOff(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/time-off-nurse/all_time_off/list/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getNurseTimeOffWithStatus(authToken, userId, status) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/time-off-nurse/all_time_off/list/with_status/${userId}/${status}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getNurseTimeOffDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/time-off-nurse/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getNurseAvaJobDateAllTimeOff(
  authToken,
  id,
  startDate,
  endDate,
  startTime,
  endTime
) {

  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    id: String(id),
    startDate: String(startDate),
    endDate: String(endDate),
    startTime: String(startTime),
    endTime: String(endTime)
  });

  const url = `${API_URL}/time-off-nurse/job/available/nurse/date/detail?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export const postNurseTimeOff = async (
  authToken,
  id,
  timeOffReason,
  startDate,
  startTime,
  endDate,
  endTime,
  alternateContactNumber,
  phoneNumber,
  organization,
  location_id,
  comments,
  status,
  nurseCancelNotes,
  approved_manager
) => { 

  if (!authToken) {
    return;
  }

  console.log({
    id,
    timeOffReason,
    startDate,
    startTime,
    endDate,
    endTime,
    alternateContactNumber,
    phoneNumber,
    organization,
    location_id,
    comments,
    status,
    nurseCancelNotes,
    approved_manager
  })

  const res = await fetch(`${API_URL}/time-off-nurse`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify({
      id,
      timeOffReason,
      startDate,
      startTime,
      endDate,
      endTime,
      alternateContactNumber,
      phoneNumber,
      organization,
      location_id,
      comments,
      status,
      nurseCancelNotes,
      approved_manager
    }),
  });
  if (res.status === 400) {
    throw new Error("Error during posting.");
  } else {
    return res.json();
  }
};

export const updateNurseTimeOff = async (authToken, id, data) => {

  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/time-off-nurse/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};