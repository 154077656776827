import React, { Component } from "react";
import { Image, Text, View } from "react-native";
import Constants from "../../../app.json";

export class AppInfo extends Component {
  render() {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Text style={{ fontSize: 25, fontWeight: "800" }}>NursdFlow</Text>
        <Text style={{ fontSize: 15, color: "#888" }}> version {Constants.expo.version}</Text>
        <Image
          source={require("../../../assets/login/NURSD-Flow.png")}
          style={{
            width: 175,
            height: 70,
            marginLeft: 0,
            marginBottom: 0,
          }}
        />
        <Text style={{ fontSize: 12, color: "#888" }}>© 2023 Nursd LLC.</Text>
      </View>
    );
  }
}

export default AppInfo;
