import {
  View,
  Text,
  Platform,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
  Alert,
  Dimensions,
  ScrollView,
  Modal,
  TextInput,
  FlatList,
} from "react-native";
import React, { useState, useEffect } from "react";
import { AntDesign, Ionicons, MaterialIcons } from "@expo/vector-icons";
import CustomInput from "../../customComponents/Input/CustomInput";
import AuthButton from "../../customComponents/Button/AuthButton";
import CalendarPicker from "react-native-calendar-picker";
import CustomDropdown from "../../customComponents/DropDown/CustomDropdown";
import CustomToggle from "../More/Setting/CustomToggle";
// import AutoFillAddress from "./AutoFillAddress";
import AutoFillAddress from "../Address/AutoFillAddress";
import CustomSliderFilter from "../../customComponents/CustomSliderFilter";
import CustomSliderFilterDropDown from "../../customComponents/CustomSliderFilterDropDown";
import {
  timingCount,
  experienceLevel,
  optionsEMR,
  options,
  option,
  specialty,
  optionBreak,
} from "../../utils/defaultData";
import {
  GetDateMergedTime,
  convertDate,
  timeDifferent,
  timeDifferentFun,
} from "../../utils/function";
import moment from "moment";
import UserJobCard from "../JobDetails/UserJobCard";
import { sendNewPushNotification } from "../../utils/notification";
import { sendNotification } from "../../utils/webNotification";
import { useAuth } from "../../context/AuthContext";
import { getFacilityUniqueIdDetails } from "../../api_url/facilityTable";
import { getCustomerList } from "../../api_url/customerTable";
import { postNurseNotification } from "../../api_url/nurseNotification";
import { updateJobTable } from "../../api_url/jobTable";
import { getSwapNurseAvailableList } from "../../api_url/nurseTable";
import { ChatState } from "../../context/ChatContext";
import { socket } from "../../api_url/socket";

const EditJobScreen = (props) => {
  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainJobDetails, setFetchAgainJobDetails
    , fetchAgainJobList, setFetchAgainJobList } = ChatState();

  const [scrollEnabled, setScrollEnabled] = useState(true);

  const { width, height } = Dimensions.get("window");
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [licenseTypeArr, setLicenseTypeArr] = useState(
    props.route.params.data.licenseType
  );
  const [certificationArr, setCertificationArr] = useState(
    props.route.params.data.certification
  );
  const [emrExperienceArr, setEmrExperienceArr] = useState(
    props.route.params.data.EMRorEHRExperience === null
      ? []
      : props.route.params.data.EMRorEHRExperience
  );

  const currentDate = moment();

  const [directAssignment, setDirectAssignment] = useState(false);
  const [facilityDetail, setFacilityDetail] = useState(undefined);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);
  const [showCertification, setShowCertification] = useState(false);
  const [showEmrExperience, setShowEmrExperience] = useState(false);
  const [showLicenseType, setShowLicenseType] = useState(false);
  const [showSpecialty, setShowSpecialty] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showAddressWeb, setShowAddressWeb] = useState(false);
  const [showBreak, setShowBreak] = useState(false);

  const [showExperienceLevel, setShowExperienceLevel] = useState(false);
  const [showTiming, setShowTiming] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [patientView, setPatientView] = useState(false);
  const [patientSearch, setPatientSearch] = useState("");

  const [showTimeWeb, setShowTimeWeb] = useState(false);
  const [showTimeEndWeb, setShowTimeEndWeb] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownMinute, setShowDropdownMinute] = useState(false);
  const [showDropdownAP, setShowDropdownAP] = useState(false);

  const [jobState, setJobState] = useState({
    shiftTitle: "",
    specialty: "",
    yearOfExperience: "",
    expiration: "",
    startDate: currentDate,
    endDate: currentDate,
    startTime: new Date(),
    endTime: moment().add(1, "hour"),
    break: "",
    customerName: "",
    customerId: "",
    unit: "",
    floor: "",
    fullAddress: "",
    latitude: "",
    longitude: "",
    baseRate: "",
    enableBid: false,
    notes: "",
    jobType: "",
    autoAcceptedEnable: false,
    maxBidAmount: "",
    jobDays: "",
    jobDuration: "",
    jobTiming: "",
    specialtyRequired: false,
    certificationRequired: false,
    experienceRequired: false,
    emrehrRequired: false,
    customerVisibility: false,
  });

  const [selectedHours, setSelectedHours] = useState("1");
  const now = new Date();
  const currentMinutes = now.getMinutes().toString().padStart(2, "0");
  const currentAmPm = now.getHours() >= 12 ? "PM" : "AM";
  const [selectedHourTime, setSelectedHourTime] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
        ? now.getHours() - 12
        : now.getHours()
  );
  const [selectedMinuteTime, setSelectedMinuteTime] = useState(currentMinutes);
  const [selectedAPTime, setSelectedAPTime] = useState(currentAmPm);

  const [showDropdownEnd, setShowDropdownEnd] = useState(false);
  const [showDropdownMinuteEnd, setShowDropdownMinuteEnd] = useState(false);
  const [showDropdownAPEnd, setShowDropdownAPEnd] = useState(false);

  const [selectedHourTimeEnd, setSelectedHourTimeEnd] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
        ? now.getHours() - 12
        : now.getHours()
  );

  const [selectedMinuteTimeEnd, setSelectedMinuteTimeEnd] = useState("00");
  const [selectedAPTimeEnd, setSelectedAPTimeEnd] = useState("AM");

  useEffect(() => {
    const nowEnd = new Date(jobState.endTime);
    const currentMinutesEnd = nowEnd.getMinutes().toString().padStart(2, "0");
    const currentAmPmEnd = nowEnd.getHours() >= 12 ? "PM" : "AM";

    setSelectedHourTimeEnd(
      nowEnd.getHours() === 0
        ? 12
        : nowEnd.getHours() > 12
          ? nowEnd.getHours() - 12
          : nowEnd.getHours()
    );
    setSelectedMinuteTimeEnd(currentMinutesEnd);
    setSelectedAPTimeEnd(currentAmPmEnd);
  }, [jobState.endTime]);

  const hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minutes = [0, 15, 30, 45];
  const ampm = ["AM", "PM"];

  useEffect(() => {
    if (props.route.params.data) {
      setJobState({
        ...jobState,
        licenseType: props.route.params.data.licenseType,
        shiftTitle: props.route.params.data.shiftTitle,
        specialty: props.route.params.data.specialty,
        certification: props.route.params.data.certification,
        yearOfExperience: props.route.params.data.yearOfExperience,
        expiration: props.route.params.data.expiration,
        startDate: props.route.params.data.startDate?.toString(),
        endDate: props.route.params.data.endDate?.toString(),
        startTime: new Date(props.route.params.data.startTime),
        endTime: new Date(props.route.params.data.endTime),
        break: props.route.params.data.break,
        fullAddress: props.route.params.data.fullAddress,
        customerName: props.route.params.data.customerName,
        customerId: props.route.params.data.customerId,
        unit: props.route.params.data.unit,
        floor: props.route.params.data.floor,
        latitude: props.route.params.data.latitude,
        longitude: props.route.params.data.longitude,
        baseRate: props.route.params.data.baseRate,
        enableBid: props.route.params.data.enableBid,
        notes: props.route.params.data.notes,
        jobType: props.route.params.data.jobType,
        autoAcceptedEnable: props.route.params.data.autoAcceptedEnable,
        maxBidAmount: props.route.params.data.maxBidAmount,
        jobDays: props.route.params.data.jobDays,
        jobDuration: props.route.params.data.jobDuration,
        jobTiming: props.route.params.data.jobTiming,
        specialtyRequired: props.route.params.data.specialtyRequired,
        certificationRequired: props.route.params.data.certificationRequired,
        experienceRequired: props.route.params.data.experienceRequired,
        emrehrRequired: props.route.params.data.emrehrRequired,
        customerVisibility: props.route.params.data.customerVisibility,
      });
      setSelectedHours(timeDifferentFun(props.route.params.data?.startTime, props.route.params.data?.endTime))
      setLoadingScreen(false)
    }
  }, [props.route.params.data]);

  //Facility Detail
  const getFacilityDetail = async (id) => {
    await getFacilityUniqueIdDetails(authToken, id).then(
      res => {
        // console.log("Facility", res)
        setFacilityDetail(res[0]);
      }
    ).catch(e => {
      console.log(e)
    })
  };

  const getFacilityPatient = async (id) => {
    await getCustomerList(authToken, id).then(
      res => {
        setPatientData(res)
      }
    ).catch(e => {
      console.log(e)
    })
  };

  useEffect(() => {
    if (userId !== undefined && userUniqueId !== undefined) {
      getFacilityPatient(userId);
      getFacilityDetail(userUniqueId);
    }
  }, [userId]);

  const handleAddLicenseType = (item) => {
    if (licenseTypeArr.some((existingItem) => existingItem === item)) {
      // Show an alert for duplicates
      if (Platform.OS === "web") {
        alert(`${item} already added.`);
      } else {
        Alert.alert(`${item} already added.`);
      }
    } else {
      // If it's not a duplicate, add the item to the skills array
      setLicenseTypeArr([...licenseTypeArr, item]);
    }
  };

  const handleRemoveLicenseType = (index) => {
    const updatedLicenseType = [...licenseTypeArr];
    updatedLicenseType.splice(index, 1);
    setLicenseTypeArr(updatedLicenseType);
  };

  const handleAddCertification = (item) => {
    setCertificationArr([...certificationArr, item]);
  };

  const handleRemoveCertification = (index) => {
    const updatedCertification = [...certificationArr];
    updatedCertification.splice(index, 1);
    setCertificationArr(updatedCertification);
  };

  const postJob = async (data) => {
    setLoading(true);
    // console.log(getTimeDifference(start, end));
    if (
      jobState.shiftTitle === "" ||
      // jobState.specialty === "" ||
      // jobState.yearOfExperience === "" || 
      jobState.startTime === "" ||
      jobState.endTime === "" ||
      jobState.break === "" ||
      jobState.fullAddress === "" ||
      jobState.latitude === "" ||
      jobState.longitude === ""
      // || jobState.baseRate === ""
    ) {
      if (Platform.OS === "web") {
        alert("Fill all required fields first");
      } else {
        Alert.alert("Fill all required fields first");
      }

      setLoading(false);
    } else if (convertDate(jobState.startDate) < convertDate(currentDate)) {
      if (Platform.OS === "web") {
        alert("Check your date");
      } else {
        Alert.alert("Check your date");
      }
      setLoading(false);
    } else if (
      convertDate(jobState.endDate) < convertDate(jobState.startDate)
    ) {
      if (Platform.OS === "web") {
        alert("Check your end date");
      } else {
        Alert.alert("Check your end date");
      }
      setLoading(false);
    }
    //  else if (getTimeDifference(start, end) > 12) {
    //   Alert.alert("The shifts can’t be more than 12 hours.");
    //   setLoading(false);
    // }
    else {
      let sDate = moment(jobState.startDate).format("YYYY-MM-DD")
      let eDate = moment(jobState.endDate).format("YYYY-MM-DD")
      await updateJobTable(
        authToken,
        props.route.params.data.id,
        {
          licenseType: licenseTypeArr,
          shiftTitle: jobState.shiftTitle,
          specialty: jobState.specialty,
          certification: certificationArr,
          EMRorEHRExperience: emrExperienceArr,
          yearOfExperience: jobState.yearOfExperience,
          expiration: jobState.expiration,
          startDate: sDate,
          endDate: eDate,
          startTime: GetDateMergedTime(jobState.startDate, jobState.startTime.toString()).toString(),
          endTime: GetDateMergedTime(jobState.endDate, jobState.endTime.toString()).toString(),
          break: jobState.break,
          customerName: jobState.customerName,
          customerId: jobState.customerId.toString(),
          unit: jobState.unit,
          floor: jobState.floor,
          fullAddress: jobState.fullAddress,
          latitude: jobState.latitude.toString(),
          longitude: jobState.longitude.toString(),
          baseRate: jobState.baseRate,
          enableBid: jobState.enableBid,
          jobAcceptedNurses: jobFinalSelectionNurseId !== "" ? [jobFinalSelectionNurseId] : [],
          jobFinalSelectionNurseId: jobFinalSelectionNurseId !== "" ? jobFinalSelectionNurseId.toString() : "",
          jobStatus: jobFinalSelectionNurseId !== "" ? "Nurse Assigned" : "Open",
          notes: jobState.notes,
          jobDuration: timeDifferent(jobState?.startTime, jobState?.endTime),
          jobType: jobState.jobType,
          autoAcceptedEnable: jobState.autoAcceptedEnable,
          maxBidAmount: jobState.maxBidAmount,
          jobDays: jobState.jobDays,
          jobTiming: jobState.jobTiming,
          specialtyRequired: jobState.specialtyRequired,
          certificationRequired: jobState.certificationRequired,
          experienceRequired: jobState.experienceRequired,
          emrehrRequired: jobState.emrehrRequired,
          customerVisibility: jobState.customerVisibility,
        }
      )
      if (jobFinalSelectionNurseId !== "") {
        const nurse = available?.filter((item) => {
          return item?.id?.id === jobFinalSelectionNurseId;
        });
        await postNurseNotification(
          authToken,
          jobFinalSelectionNurseId,
          "",
          `“Direct ${jobState.jobType}s Assignment” (Check Upcoming Jobs)`,
          `${jobState.jobType}s assigned.`,
          "HomeScreen",
          {
            screen: "UpComing",
          },
          false,
          false,
          "green",
          ""
        )
        socket?.emit("tableNotificationInsert",
          {
            table: "NurseNotificationTable",
            id: jobFinalSelectionNurseId?.toString()
          })
        async function send() {
          if (Platform.OS !== "web") {
            await sendNewPushNotification({
              expoPushToken: nurse[0]?.id?.mobileId,
              job: `“Direct ${jobState.jobType}s Assignment” (Check Upcoming Jobs)`,
              typeMessage: `${jobState.jobType}s assigned.`,
              screen: "HomeScreen",
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: nurse[0]?.id?.mobileId,
              title: `“Direct ${jobState.jobType}s Assignment” (Check Upcoming Jobs)`,
              body: `${jobState.jobType}s assigned.`,
              data: { screen: "HomeScreen" },
            })
          }
        }
        if (nurse[0]?.id?.mobileId) {
          send();
        }
      }
      setFetchAgainJobList(!fetchAgainJobList);
      setFetchAgainJobDetails(!fetchAgainJobDetails);
      props.navigation.goBack();
    }
  };

  const handleAddEmrExperience = (item) => {
    setEmrExperienceArr([...emrExperienceArr, item]);
  };

  const handleRemoveEmrExperience = (index) => {
    const updatedEmrExperience = [...emrExperienceArr];
    updatedEmrExperience.splice(index, 1);
    setEmrExperienceArr(updatedEmrExperience);
  };
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const filterSuggestions = (query) => {
    if (query === "") {
      setFilteredSuggestions([]);
    } else {
      const filtered = patientData?.filter(
        (item) =>
          item.patient_first_name
            ?.toLowerCase()
            .includes(query.toLowerCase()) ||
          item.patient_last_name?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    }
  };

  const renderSuggestion = ({ item }) => {
    return (
      <TouchableOpacity
        style={{
          paddingVertical: 10,
          borderRadius: 10,
          paddingLeft: 5,
          borderColor: "#dddd",
          borderBottomWidth: 2,
        }}
        onPress={async () => {
          // let address =
          //   item?.patient_address_line_1 +
          //   "," +
          //   item?.patient_city +
          //   "," +
          //   item?.patient_state +
          //   "," +
          //   item?.patient_zip_code;

          // const GOOGLE_PLACES_API_KEY =
          //   "AIzaSyASXZSrsvL25WiY7nZc2M4WJhkDMj-jkvs";
          // const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          //   address
          // )}&key=${GOOGLE_PLACES_API_KEY}`;

          try {
            // const response = await fetch(apiUrl);
            // const data = await response.json();
            // // console.log(data);
            // const state = data.results[0].address_components.find((component) =>
            //   component.types.includes("administrative_area_level_1")
            // );

            setJobState({
              ...jobState,
              customerId: item?.id,
              customerName:
                item?.firstName + " " + item?.lastName,
              fullAddress: item?.fullAddress,
              latitude: item?.latitude,
              longitude: item?.longitude,
              // unit: item?.patient_address_line_2
              //   ? item?.patient_address_line_2
              //   : "",
            });
            setPatientSearch("");
            setFilteredSuggestions([]);
            setPatientView(false);
          } catch (error) {
            console.error("Error:", error);
            return null;
          }
        }}
      >
        <Text>{item?.firstName + " " + item?.lastName}</Text>
      </TouchableOpacity>
    );
  };

  const isPastDate = (date) => {
    return date.isBefore(currentDate, "day");
  };

  const disabledDates = (date) => {
    return isPastDate(date);
  };

  const isStartPastDate = (date) => {
    return date.isBefore(moment(jobState.startDate), "day");
  };

  const disabledStartDates = (date) => {
    return isStartPastDate(date);
  };

  const disabledDatesTextStyle = { color: "#CCCCCC" };

  const [available, setAvailable] = useState(undefined);
  const [jobFinalSelectionNurseId, setJobFinalSelectionNurseId] = useState("");

  useEffect(() => {
    if (props.route.params.data !== undefined && facilityDetail !== undefined) {

      const get = async () => {
        await getSwapNurseAvailableList(
          authToken,
          facilityDetail?.organization,
          facilityDetail?.location_id,
          jobState?.startDate,
          jobState?.endDate,
          moment(new Date(jobState?.startTime)).format("hh:mm a"),
          moment(new Date(jobState?.endTime)).format("hh:mm a"),
          licenseTypeArr,
          jobState?.fullAddress
        ).then(
          res => {
            setAvailable(res)
          }
        ).catch(e => console.log(e))
      }

      get();
    }
  }, [props.route.params.data, licenseTypeArr, facilityDetail, jobState]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={styles.container}
    >
      {loadingScreen ?
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View> :
        <View>
          {!showAddressWeb &&
            <ScrollView
              bounces={false}
              showsVerticalScrollIndicator={false}
              scrollEnabled={Platform.OS === "web" ? true : scrollEnabled}>
              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10,
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  Job Type
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <View
                  style={{
                    flex: 1,
                    height: 40,
                    borderWidth: 1,
                    borderColor: "#e6e6e6",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    flexDirection: "row",
                    marginTop: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 10,
                  }}
                >
                  <Text style={{ fontSize: 12, color: "#737373" }}>
                    {jobState?.jobType}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10,
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  License Type
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <TouchableOpacity
                  style={{
                    flex: 1,
                    height: 40,
                    borderWidth: 1,
                    borderColor: "#e6e6e6",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    flexDirection: "row",
                    marginTop: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 10,
                  }}
                  onPress={() => setShowLicenseType(true)}
                >
                  <Text style={{ fontSize: 12, color: "#737373" }}>
                    Add License Type
                  </Text>
                  <MaterialIcons
                    name={"keyboard-arrow-down"}
                    size={24}
                    color="#b3b3b3"
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {licenseTypeArr?.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          marginRight: 10,
                          marginTop: 10,
                        }}
                      >
                        <Text style={{ fontSize: 12, color: "#737373" }}>
                          {item}
                        </Text>
                        <TouchableOpacity
                          onPress={() => handleRemoveLicenseType(index)}
                        >
                          <MaterialIcons
                            name={"delete-outline"}
                            size={24}
                            color="#999"
                          />
                        </TouchableOpacity>
                      </View>
                    );
                  })}
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 20,
                }}
              >
                <CustomInput
                  required={true}
                  label={
                    jobState.jobType === "Shift" ? "Shift Title" : "Visit Title"
                  }
                  onChangeText={(text) =>
                    setJobState({ ...jobState, shiftTitle: text })
                  }
                  keyboardType={"default"}
                  placeholder={"Add Title"}
                  value={jobState.shiftTitle}
                  clearValue={() => setJobState({ ...jobState, shiftTitle: "" })}
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                    marginTop: 0,
                  }}
                  textInputStyle={{
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    paddingRight: 5,
                    fontSize: 12,
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                />
              </View>

              {/* <View
            style={{
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#737373",
                  },
                ]}
              >
                Specialty
              </Text>
              <CustomToggle
                state={jobState.specialtyRequired}
                onValueChange={(value) =>
                  setJobState({ ...jobState, specialtyRequired: value })
                }
              />
            </View>
            <View
              style={{
                marginTop: 10,
                height: 40,
              }}
            >
              <TouchableOpacity
                style={{
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  height: 40,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: 10,
                }}
                onPress={() => setShowSpecialty(true)}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  {jobState.specialty === "" || jobState.specialty === null
                    ? "Select Specialty"
                    : jobState.specialty}
                </Text>
                <MaterialIcons
                  name={"keyboard-arrow-down"}
                  size={24}
                  color="#808080"
                />
              </TouchableOpacity>
            </View>
          </View> */}

              {/* <View
            style={{
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#737373",
                  },
                ]}
              >
                Certification
              </Text>
              <CustomToggle
                state={jobState.certificationRequired}
                onValueChange={(value) =>
                  setJobState({ ...jobState, certificationRequired: value })
                }
              />
            </View>
            <TouchableOpacity
              style={{
                flex: 1,
                height: 40,
                borderWidth: 1,
                borderColor: "#e6e6e6",
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                flexDirection: "row",
                marginTop: 5,
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 10,
              }}
              onPress={() => setShowCertification(true)}
            >
              <Text style={{ fontSize: 12, color: "#737373" }}>
                Add Certification
              </Text>
              <MaterialIcons
                name={"keyboard-arrow-down"}
                size={24}
                color="#b3b3b3"
              />
            </TouchableOpacity>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {certificationArr?.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      marginRight: 10,
                      marginTop: 10,
                    }}
                  >
                    <Text style={{ fontSize: 12, color: "#737373" }}>
                      {item}
                    </Text>
                    <TouchableOpacity
                      onPress={() => handleRemoveCertification(index)}
                    >
                      <MaterialIcons
                        name={"delete-outline"}
                        size={24}
                        color="#999"
                      />
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          </View> */}

              {/* <View
            style={{
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#737373",
                  },
                ]}
              >
                Experience Level
              </Text>
              <CustomToggle
                state={jobState.experienceRequired}
                onValueChange={(value) =>
                  setJobState({ ...jobState, experienceRequired: value })
                }
              />
            </View>
            <View
              style={{
                marginTop: 10,
                height: 40,
              }}
            >
              <TouchableOpacity
                style={{
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  height: 40,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: 10,
                }}
                onPress={() => setShowExperienceLevel(true)}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  {jobState.yearOfExperience === "" ||
                  jobState.yearOfExperience === null
                    ? "Select Experience Level"
                    : jobState.yearOfExperience}
                </Text>
                <MaterialIcons
                  name={"keyboard-arrow-down"}
                  size={24}
                  color="#808080"
                />
              </TouchableOpacity>
            </View>
          </View> */}

              {/* <View
            style={{
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#737373",
                  },
                ]}
              >
                EMR or EHR Experience
              </Text>
              <CustomToggle
                state={jobState.emrehrRequired}
                onValueChange={(value) =>
                  setJobState({ ...jobState, emrehrRequired: value })
                }
              />
            </View>
            <TouchableOpacity
              style={{
                flex: 1,
                height: 40,
                borderWidth: 1,
                borderColor: "#e6e6e6",
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                flexDirection: "row",
                marginTop: 5,
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 10,
              }}
              onPress={() => setShowEmrExperience(true)}
            >
              <Text style={{ fontSize: 12, color: "#737373" }}>
                Add EMR or EHR Experience
              </Text>
              <MaterialIcons
                name={"keyboard-arrow-down"}
                size={24}
                color="#b3b3b3"
              />
            </TouchableOpacity>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {emrExperienceArr?.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      marginRight: 10,
                      marginTop: 10,
                    }}
                  >
                    <Text style={{ fontSize: 12, color: "#737373" }}>
                      {item}
                    </Text>
                    <TouchableOpacity
                      onPress={() => handleRemoveEmrExperience(index)}
                    >
                      <MaterialIcons
                        name={"delete-outline"}
                        size={24}
                        color="#999"
                      />
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          </View> */}

              {/* <View
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <Text
              style={[
                {
                  fontSize: 12,
                  color: "#737373",
                  marginBottom: 10,
                },
              ]}
            >
              Job Expiration (
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#000",
                    fontWeight: "bold",
                    marginBottom: 10,
                  },
                ]}
              >
                {" "}
                {jobState.expiration !== "" && `${jobState.expiration} hr`}
              </Text>{" "}
              Hours before the Start Time).
            </Text>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                top: 3,
              }}
            >
              <Text style={{ fontSize: 12 }}>1 hr</Text>
              <Text style={{ fontSize: 12 }}>2 4hr</Text>
            </View>
            <View style={{ marginTop: -10 }}>
              <CustomSliderFilter
                sliderWidth={width / 1.5}
                min={1}
                max={24}
                step={1}
                type={""}
                label={true}
                color={"#00b359"}
                onValueChange={(range) => {
                  setJobState({ ...jobState, expiration: range.max });
                  // setState({
                  //   ...state,
                  //   distancePreferenceMinValue: range.min,
                  //   distancePreferenceMaxValue: range.max,
                  // });
                }}
                valueMin={0}
                valueMax={props.route.params.data.expiration}
                minDrag={false}
              />
            </View>
          </View> */}

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10,
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  {jobState?.jobType === "Shift" ? "Shift Time" : "Visit Time"}
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    height: 40,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10,
                    }}
                    onPress={() => setShowTiming(true)}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373",
                        },
                      ]}
                    >
                      {jobState.jobTiming === ""
                        ? "Select Time"
                        : jobState.jobTiming}
                    </Text>
                    <MaterialIcons
                      name={"keyboard-arrow-down"}
                      size={24}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              {/* <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginHorizontal: 18,
              marginTop: 10,
            }}
          >
            <Text
              style={[
                {
                  fontSize: 12,
                  color: "#737373",
                },
              ]}
            >
              Address
            </Text>
            <Text style={{ color: "red" }}> * </Text>
            <Text style={{ fontSize: 10, color: "#000" }}>
              ( Make sure your Date and Time selection)
            </Text>
          </View> */}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 20,
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373",
                    }}
                  >
                    Start Date <Text style={{ color: "red" }}>*</Text>
                  </Text>
                  <TouchableOpacity
                    onPress={() => setShowCalendar(true)}
                    style={{
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10,
                      marginRight: 5,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      {moment(jobState.startDate).format("MM-DD-YYYY")}
                    </Text>
                    <AntDesign name="calendar" size={20} color="#808080" />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373",
                    }}
                  >
                    Start Time <Text style={{ color: "red" }}>*</Text>
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      Platform.OS === "ios"
                        ? setShowTimeWeb(true)
                        : Platform.OS === "android"
                          ? // ? setShowTime(true)
                          setShowTimeWeb(true)
                          : setShowTimeWeb(true)
                    }
                    style={{
                      flex: 1,
                      marginLeft: 5,
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      {moment(jobState.startTime).format("h:mm a")}
                    </Text>
                    <MaterialIcons name="access-time" size={20} color="#808080" />
                  </TouchableOpacity>
                </View>
              </View>

              {jobState.jobType === "Shift" &&
                <View
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginHorizontal: 10,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginHorizontal: 20,
                      marginTop: 10
                    }}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373",
                        },
                      ]}
                    >
                      Select Hour's (
                      <Text
                        style={[
                          {
                            fontSize: 12,
                            color: "#000",
                            fontWeight: "bold",
                            marginBottom: 10,
                          },
                        ]}
                      >
                        {selectedHours !== "" &&
                          `${selectedHours} ${selectedHours >= 2 ? "Hour(s)" : "Hour"
                          }`}
                      </Text>
                      )<Text style={{ color: "red" }}>*</Text>
                    </Text>
                  </View>
                  {/* <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    top: 10,
                  }}
                >
                  <Text style={{ fontSize: 12 }}>1 H</Text>
                  <Text style={{ fontSize: 12 }}>12 H</Text>
                </View> */}
                  <View>
                    {/* <CustomSliderFilter
                    sliderWidth={width / 1.3}
                    min={1}
                    max={12}
                    step={1}
                    type={""}
                    label={true}
                    color={"#00b359"}
                    onValueChange={(range) => {
                      setSelectedHours(range.max);

                      let date = new Date();
                      let dateEnd = new Date();

                      let nowEnd = new Date(jobState.startTime);
                      let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                      let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                      let selectedHourTime = nowEnd.getHours() === 0
                        ? 12
                        : nowEnd.getHours() > 12
                          ? nowEnd.getHours() - 12
                          : nowEnd.getHours()

                      date.setHours(
                        selectedAPTime === "AM" && selectedHourTime === 12
                          ? 0
                          : selectedAPTime === "PM" && selectedHourTime === 12
                            ? 12
                            : selectedHourTime !== 12 && selectedAPTime === "PM"
                              ? parseInt(selectedHourTime) + 12
                              : parseInt(selectedHourTime)
                      );
                      date.setMinutes(selectedMinuteTime);

                      // Add 8 hours to dateEnd based on date
                      dateEnd.setFullYear(date.getFullYear());
                      dateEnd.setMonth(date.getMonth());
                      dateEnd.setDate(date.getDate());
                      dateEnd.setHours(date.getHours() + range.max);
                      dateEnd.setMinutes(date.getMinutes());

                      // Determine if it's AM or PM
                      let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                      if (selectedAPTime === "PM" && meridiem === "AM") {
                        let endDate = moment(jobState.startDate);
                        endDate.add(1, "day")
                        setJobState({
                          ...jobState,
                          startTime: date,
                          endTime: dateEnd,
                          endDate: endDate,
                        });
                      } else {
                        setJobState({
                          ...jobState,
                          startTime: date,
                          endTime: dateEnd,
                          endDate: jobState.startDate,
                        });
                      }
                    }}
                    valueMin={0}
                    valueMax={parseInt(selectedHours)}
                    minDrag={false}
                  /> */}
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#737373",
                        marginLeft: 10,
                        marginBottom: 2
                      }}
                    >
                      Duration
                    </Text>
                    <CustomSliderFilterDropDown
                      sliderWidth={10}
                      min={1}
                      max={12}
                      step={1}
                      type={""}
                      label={true}
                      color={"#737373"}
                      onValueChange={(range) => {
                        setSelectedHours(range.max);

                        let date = new Date();
                        let dateEnd = new Date();

                        let nowEnd = new Date(jobState.startTime);
                        let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                        let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                        let selectedHourTime = nowEnd.getHours() === 0
                          ? 12
                          : nowEnd.getHours() > 12
                            ? nowEnd.getHours() - 12
                            : nowEnd.getHours()

                        date.setHours(
                          selectedAPTime === "AM" && selectedHourTime === 12
                            ? 0
                            : selectedAPTime === "PM" && selectedHourTime === 12
                              ? 12
                              : selectedHourTime !== 12 && selectedAPTime === "PM"
                                ? parseInt(selectedHourTime) + 12
                                : parseInt(selectedHourTime)
                        );
                        date.setMinutes(selectedMinuteTime);

                        // Add 8 hours to dateEnd based on date
                        dateEnd.setFullYear(date.getFullYear());
                        dateEnd.setMonth(date.getMonth());
                        dateEnd.setDate(date.getDate());
                        dateEnd.setHours(date.getHours() + range.max);
                        dateEnd.setMinutes(date.getMinutes());

                        // Determine if it's AM or PM
                        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                        if (selectedAPTime === "PM" && meridiem === "AM") {
                          let endDate = moment(jobState.startDate);
                          endDate.add(1, "day")
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: endDate,
                          });
                          setScrollEnabled(!scrollEnabled);
                        } else {
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: jobState.startDate,
                          });
                          setScrollEnabled(!scrollEnabled);
                        }
                      }}
                      valueMin={0}
                      valueMax={parseInt(selectedHours)}
                      minDrag={false}
                      dType={"time"}
                      scrollEnabled={scrollEnabled}
                      setScrollEnabled={setScrollEnabled}
                    />
                  </View>
                </View>}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 20,
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373",
                    }}
                  >
                    End Date
                  </Text>
                  <TouchableOpacity
                    // onPress={() => setShowCalendarEnd(true)}
                    style={{
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10,
                      marginRight: 5,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      {moment(jobState.endDate).format("MM-DD-YYYY")}
                    </Text>
                    <AntDesign name="calendar" size={20} color="#808080" />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373",
                    }}
                  >
                    End Time
                  </Text>
                  <TouchableOpacity
                    // onPress={() => setShowTimeEndWeb(true)}
                    style={{
                      flex: 1,
                      marginLeft: 5,
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      {moment(jobState.endTime).format("h:mm a")}
                    </Text>
                    <MaterialIcons name="access-time" size={20} color="#808080" />
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10,
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  Break
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    height: 40,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10,
                    }}
                    onPress={() => setShowBreak(true)}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373",
                        },
                      ]}
                    >
                      {jobState.break === "" ? "Select Break" : jobState.break}
                    </Text>
                    <MaterialIcons
                      name={"keyboard-arrow-down"}
                      size={24}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              {/* <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <CustomInput
              required={false}
              label={"Customer Name"}
              onChangeText={(text) =>
                setJobState({ ...jobState, selectCustomer: text })
              }
              keyboardType={"default"}
              placeholder={"Enter Customer Name"}
              value={jobState.selectCustomer}
              clearValue={() =>
                setJobState({ ...jobState, selectCustomer: "" })
              }
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                paddingRight: 10,
                justifyContent: "space-between",
                marginTop: 0,
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                paddingRight: 5,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
          </View>*/}

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      color: "#737373",
                    }}
                  >
                    Customer Name
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      After Assignment
                    </Text>
                    <CustomToggle
                      state={jobState.customerVisibility}
                      onValueChange={(value) =>
                        setJobState({ ...jobState, customerVisibility: value })
                      }
                    />
                  </View>
                </View>

                <TouchableOpacity
                  onPress={() => setPatientView(true)}
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      color: "#737373",
                      paddingLeft: 5,
                      fontSize: 12,
                    }}
                  >
                    {jobState.customerName === ""
                      ? "Select Customer"
                      : jobState?.customerName}
                  </Text>
                </TouchableOpacity>
              </View>

              <View style={{ marginHorizontal: 18, marginTop: 10 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373",
                      },
                    ]}
                  >
                    Address
                  </Text>
                  <Text style={{ color: "red" }}>*</Text>
                </View>
                <View
                  style={{
                    marginTop: 10,
                    // height: 40,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      // height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10,
                      paddingVertical: 10,
                    }}
                    onPress={() => {
                      Platform.OS === "web" ?
                        setShowAddressWeb(true) :
                        setShowAddress(true)
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      {jobState.fullAddress !== ""
                        ? jobState.fullAddress
                        : "Enter Address"}
                    </Text>
                    {jobState.fullAddress === "" && (
                      <MaterialIcons name={"add"} size={24} color="#808080" />
                    )}
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 10,
                }}
              >
                <CustomInput
                  label={"Unit"}
                  required={false}
                  onChangeText={(text) => setJobState({ ...jobState, unit: text })}
                  keyboardType={"default"}
                  placeholder={"Unit"}
                  value={jobState.unit}
                  clearValue={() => setJobState({ ...jobState, unit: "" })}
                  labelStyle={{
                    marginHorizontal: 10,
                  }}
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                  }}
                  textInputStyle={{
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    fontSize: 12,
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                />
                <CustomInput
                  label={"Floor"}
                  required={false}
                  onChangeText={(text) => setJobState({ ...jobState, floor: text })}
                  keyboardType={"default"}
                  placeholder={"Floor"}
                  value={jobState.floor}
                  clearValue={() => setJobState({ ...jobState, floor: "" })}
                  labelStyle={{
                    marginHorizontal: 10,
                  }}
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                  }}
                  textInputStyle={{
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    fontSize: 12,
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                />
              </View>

              {/* <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <CustomInput
              required={true}
              label={"Base Rate"}
              onChangeText={(text) =>
                setJobState({ ...jobState, baseRate: text })
              }
              keyboardType={"default"}
              placeholder={"Rate $"}
              value={jobState.baseRate}
              clearValue={() => setJobState({ ...jobState, baseRate: "" })}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                paddingRight: 10,
                justifyContent: "space-between",
                marginTop: 0,
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                paddingRight: 5,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
          </View> */}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 20,
                }}
              >
                <CustomInput
                  label={"Notes"}
                  onChangeText={(text) => setJobState({ ...jobState, notes: text })}
                  keyboardType={"default"}
                  placeholder={"Add Notes"}
                  value={jobState.notes}
                  clearValue={() => setJobState({ ...jobState, notes: "" })}
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                    marginTop: 0,
                  }}
                  textInputStyle={{
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    paddingRight: 5,
                    fontSize: 12,
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                />
              </View>

              {/* {!jobState?.autoAcceptedEnable && (
            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                paddingHorizontal: 10,
                height: 40,
                elevation: 1,
                marginHorizontal: 15,
                alignItems: "center",
                backgroundColor: "#f2f2f2",
                borderWidth: 1,
                borderColor: "#e6e6e6",
                borderRadius: 10,
                marginTop: 12,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                Enable Bid
              </Text>
              <CustomToggle
                state={jobState.enableBid}
                onValueChange={(value) =>
                  setJobState({ ...jobState, enableBid: value })
                }
              />
            </View>
          )} */}
              {/* {!jobState?.enableBid && (
            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                paddingHorizontal: 10,
                marginHorizontal: 15,
                height: 40,
                elevation: 1,
                alignItems: "center",
                backgroundColor: "#f2f2f2",
                borderWidth: 1,
                borderColor: "#e6e6e6",
                borderRadius: 10,
                marginTop: 12,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                Enable Auto Approval
              </Text>
              <CustomToggle
                state={jobState.autoAcceptedEnable}
                onValueChange={(value) =>
                  setJobState({ ...jobState, autoAcceptedEnable: value })
                }
              />
            </View>
          )} */}

              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                  paddingHorizontal: 10,
                  marginHorizontal: 20,
                  height: 42,
                  elevation: 1,
                  alignItems: "center",
                  backgroundColor: "#f2f2f2",
                  borderWidth: 1,
                  borderColor: "#e6e6e6",
                  borderRadius: 10,
                  marginTop: 12,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  Direct Assignment
                </Text>
                <CustomToggle
                  state={directAssignment}
                  onValueChange={(value) => {
                    licenseTypeArr?.length === 0
                      ? Platform.OS === "web"
                        ? alert("Select license type")
                        : Alert.alert("Select license type")
                      : jobState?.fullAddress === ""
                        ? Platform.OS === "web"
                          ? alert("Enter address")
                          : Alert.alert("Enter address")
                        : setDirectAssignment(value);
                    setJobFinalSelectionNurseId("");
                  }}
                />
              </View>

              {directAssignment && <View style={{ marginTop: 10, marginHorizontal: 20 }}>
                <Text>Available Nurse List</Text>
                {!available ? (
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#000",
                        textAlign: "center",
                        marginHorizontal: 20,
                      },
                    ]}
                  >
                    Loading...
                  </Text>
                ) : available
                  ?.filter((obj, index, arr) => {
                    const jobAcceptedNurses = props.route.params.data?.jobAcceptedNurses;
                    const isMatched = jobAcceptedNurses?.some(
                      (item) => item === obj.id?.id
                    );
                    return obj.id?.availableTime && !isMatched;
                  })
                  .length === 0 ? (
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#000",
                        textAlign: "center",
                        marginHorizontal: 20,
                      },
                    ]}
                  >
                    No nurse is available.
                  </Text>
                ) : (
                  available
                    ?.filter((obj, index, arr) => {
                      const jobAcceptedNurses = props.route.params.data?.jobAcceptedNurses;
                      const isMatched = jobAcceptedNurses?.some(
                        (item) => item === obj.id?.id
                      );
                      return obj.id?.availableTime && !isMatched;
                    })
                    .map((item, index) => {
                      return (
                        <UserJobCard
                          key={index}
                          element={item}
                          jobFinalSelectionNurseId={jobFinalSelectionNurseId}
                          setJobFinalSelectionNurseId={
                            setJobFinalSelectionNurseId
                          }
                        />
                      );
                    })
                )}
              </View>}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 40,
                }}
              >
                <AuthButton
                  name={loading ? "Loading..." : "Save"}
                  onPress={() =>
                    loading
                      ? null
                      : Platform.OS === "web"
                        ? confirm(
                          "Confirm\nReview Date and Time before save?"
                        ).valueOf(true) && postJob(props.route.params.data)
                        : Alert.alert(
                          "Confirm",
                          "Review Date and Time before save?",
                          [
                            {
                              text: "Cancel",
                              onPress: () => console.log("Cancel Pressed"),
                              style: "cancel",
                            },
                            {
                              text: "OK",
                              onPress: () => postJob(props.route.params.data),
                            },
                          ]
                        )
                  }
                  color={"#00b359"}
                />
              </View>
            </ScrollView>}

          {showAddressWeb && Platform.OS === "web" &&
            <View
              style={{
                height: height,
                width: width,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                position: "absolute"
              }}>
              <AutoFillAddress
                setShowAddress={setShowAddressWeb}
                jobState={jobState}
                setJobState={setJobState}
              />
            </View>}

          <Modal
            animationType="fade"
            transparent={true}
            visible={showAddress}
            onRequestClose={() => setShowAddress(false)}
          >
            <AutoFillAddress
              setShowAddress={setShowAddress}
              jobState={jobState}
              setJobState={setJobState}
            />
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showCalendar}
            onRequestClose={() => setShowCalendar(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 20,
                  justifyContent: "center",
                  elevation: 5,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <CalendarPicker
                  onDateChange={(date) => {
                    const startTimeCal = new Date(jobState.startTime);
                    const startTimeAmPm = startTimeCal.getHours() >= 12 ? "PM" : "AM";
                    const endTimeCal = new Date(jobState.endTime);
                    const endTimeAmPm = endTimeCal.getHours() >= 12 ? "PM" : "AM";

                    if (startTimeAmPm === "PM" && endTimeAmPm === "AM") {
                      const getDateForEndDate = moment(date);
                      // Add one day
                      var nightEndDate = getDateForEndDate.add(1, 'day');
                      // Format the new date if needed
                      var endDate = nightEndDate.format('YYYY-MM-DD');
                      setJobState({
                        ...jobState,
                        startDate: moment(date).format('YYYY-MM-DD'),
                        endDate: endDate,
                      });
                    } else {
                      setJobState({
                        ...jobState,
                        startDate: moment(date).format('YYYY-MM-DD'),
                        endDate: moment(date).format('YYYY-MM-DD'),
                      })
                    }
                  }
                  }
                  selectedDayColor="#00b359"
                  selectedDayTextColor="#FFFFFF"
                  initialDate={new Date()}
                  disabledDates={disabledDates}
                  disabledDatesTextStyle={disabledDatesTextStyle}
                />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCalendar(false)}
                    style={{
                      backgroundColor: "#00b359",
                      marginHorizontal: 20,
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showCalendarEnd}
            onRequestClose={() => setShowCalendarEnd(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 20,
                  justifyContent: "center",
                  elevation: 5,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <CalendarPicker
                  onDateChange={(date) =>
                    setJobState({ ...jobState, endDate: moment(date).format('YYYY-MM-DD') })
                  }
                  selectedDayColor="#00b359"
                  selectedDayTextColor="#FFFFFF"
                  initialDate={new Date()}
                  disabledDates={disabledStartDates}
                  disabledDatesTextStyle={disabledDatesTextStyle}
                />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCalendarEnd(false)}
                    style={{
                      backgroundColor: "#00b359",
                      marginHorizontal: 20,
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showTimeWeb}
            onRequestClose={() => setShowTimeWeb(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 18, marginBottom: 0 }}>
                  Select Start Time{" "}
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 10 }}>
                  Select Hour and Minutes
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdown(!showDropdown);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdown ? "#00b359" : "#000",
                      }}
                    >
                      {selectedHourTime === "" ? "00" : selectedHourTime}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownMinute(!showDropdownMinute);
                      setShowDropdown(false);
                      setShowDropdownAP(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownMinute ? "#00b359" : "#000",
                      }}
                    >
                      {selectedMinuteTime === "" ? "00" : selectedMinuteTime}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownAP(!showDropdownAP);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownAP ? "#00b359" : "#000",
                      }}
                    >
                      {selectedAPTime === "" ? "AM" : selectedAPTime}
                    </Text>
                  </TouchableOpacity>
                </View>
                {showDropdown && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {hour.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedHourTime(item < 10 ? "0" + item : item);
                            setShowDropdown(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownMinute && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    <TextInput
                      style={{
                        width: "90%",
                        padding: 2,
                        paddingLeft: 5,
                        fontSize: 12,
                        color: "#737373",
                      }}
                      keyboardType={"decimal-pad"}
                      onChangeText={(text) => {
                        setSelectedMinuteTime(text);
                      }}
                      value={
                        selectedMinuteTime === "00" ? "" : selectedMinuteTime
                      }
                      placeholderTextColor="#b3b3b3"
                      placeholder={`Enter custom minutes`}
                    />
                    {minutes.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedMinuteTime(item < 10 ? "0" + item : item);
                            setShowDropdownMinute(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownAP && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 100,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {ampm.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedAPTime(item);
                            setShowDropdownAP(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setShowTimeWeb(false);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                    style={{
                      backgroundColor: "#888",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginHorizontal: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      if (parseInt(selectedHours) === 1) {
                        let date = new Date();
                        let dateEnd = new Date();
                        date.setHours(
                          selectedAPTime === "AM" && selectedHourTime === 12
                            ? 0
                            : selectedAPTime === "PM" && selectedHourTime === 12
                              ? 12
                              : selectedHourTime !== 12 && selectedAPTime === "PM"
                                ? parseInt(selectedHourTime) + 12
                                : parseInt(selectedHourTime)
                        );
                        date.setMinutes(selectedMinuteTime);
                        // Add 8 hours to dateEnd based on date
                        dateEnd.setFullYear(date.getFullYear());
                        dateEnd.setMonth(date.getMonth());
                        dateEnd.setDate(date.getDate());
                        // dateEnd.setHours(date.getHours() + (jobState.jobType === "Shift" ? 8 : 1));
                        dateEnd.setHours(date.getHours() + 1);
                        dateEnd.setMinutes(date.getMinutes());

                        // Determine if it's AM or PM
                        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                        if (selectedAPTime === "PM" && meridiem === "AM") {
                          let endDate = moment(jobState.startDate);
                          endDate.add(1, "day")
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: endDate,
                          });
                        } else {
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: jobState.startDate,
                          });
                        }
                      } else {
                        let date = new Date();
                        let dateEnd = new Date();
                        date.setHours(
                          selectedAPTime === "AM" && selectedHourTime === 12
                            ? 0
                            : selectedAPTime === "PM" && selectedHourTime === 12
                              ? 12
                              : selectedHourTime !== 12 && selectedAPTime === "PM"
                                ? parseInt(selectedHourTime) + 12
                                : parseInt(selectedHourTime)
                        );
                        date.setMinutes(selectedMinuteTime);
                        // Add 8 hours to dateEnd based on date
                        dateEnd.setFullYear(date.getFullYear());
                        dateEnd.setMonth(date.getMonth());
                        dateEnd.setDate(date.getDate());
                        // dateEnd.setHours(date.getHours() + (jobState.jobType === "Shift" ? 8 : 1));
                        dateEnd.setHours(date.getHours() + parseInt(selectedHours));
                        dateEnd.setMinutes(date.getMinutes());

                        // Determine if it's AM or PM
                        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                        if (selectedAPTime === "PM" && meridiem === "AM") {
                          let endDate = moment(jobState.startDate);
                          endDate.add(1, "day")
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: endDate,
                          });
                        } else {
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: jobState.startDate,
                          });
                        }
                      }
                      setShowTimeWeb(false);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showTimeEndWeb}
            onRequestClose={() => setShowTimeEndWeb(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 18, marginBottom: 0 }}>
                  Select End Time
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 10 }}>
                  Select Hour and Minutes
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownEnd(!showDropdownEnd);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownEnd ? "#00b359" : "#000",
                      }}
                    >
                      {selectedHourTimeEnd === "" ? "00" : selectedHourTimeEnd}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownMinuteEnd(!showDropdownMinuteEnd);
                      setShowDropdownEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownMinuteEnd ? "#00b359" : "#000",
                      }}
                    >
                      {selectedMinuteTimeEnd === ""
                        ? "00"
                        : selectedMinuteTimeEnd}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownAPEnd(!showDropdownAPEnd);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownAPEnd ? "#00b359" : "#000",
                      }}
                    >
                      {selectedAPTimeEnd === "" ? "AM" : selectedAPTimeEnd}
                    </Text>
                  </TouchableOpacity>
                </View>

                {showDropdownEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {hour.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedHourTimeEnd(item < 10 ? "0" + item : item);
                            setShowDropdownEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownMinuteEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    <TextInput
                      style={{
                        width: "90%",
                        padding: 2,
                        paddingLeft: 5,
                        fontSize: 12,
                        color: "#737373",
                      }}
                      keyboardType={"decimal-pad"}
                      onChangeText={(text) => {
                        setSelectedMinuteTimeEnd(text);
                      }}
                      value={
                        selectedMinuteTimeEnd === "00"
                          ? ""
                          : selectedMinuteTimeEnd
                      }
                      placeholderTextColor="#b3b3b3"
                      placeholder={`Enter custom minutes`}
                    />
                    {minutes.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedMinuteTimeEnd(
                              item < 10 ? "0" + item : item
                            );
                            setShowDropdownMinuteEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}

                {showDropdownAPEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 100,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {ampm.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedAPTimeEnd(item);
                            setShowDropdownAPEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setShowTimeEndWeb(false);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                    style={{
                      backgroundColor: "#888",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginHorizontal: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      let date = new Date();
                      date.setHours(
                        selectedHourTimeEnd === 12 && selectedAPTimeEnd === "AM"
                          ? 0
                          : selectedAPTimeEnd === "PM" &&
                            selectedHourTimeEnd === 12
                            ? 12
                            : selectedHourTimeEnd !== 12 &&
                              selectedAPTimeEnd === "PM"
                              ? parseInt(selectedHourTimeEnd) + 12
                              : parseInt(selectedHourTimeEnd)
                      );
                      date.setMinutes(selectedMinuteTimeEnd);

                      setJobState({ ...jobState, endTime: date });
                      setShowTimeEndWeb(false);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showCertification}
            onRequestClose={() => setShowCertification(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Certification
                </Text>
                <CustomDropdown
                  options={options}
                  selectedValue={"Certification"}
                  onValueChange={(value) => handleAddCertification(value)}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCertification(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showSpecialty}
            onRequestClose={() => setShowSpecialty(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Specialty
                </Text>
                <CustomDropdown
                  options={specialty}
                  selectedValue={"Specialty"}
                  onValueChange={(value) => {
                    setJobState({ ...jobState, specialty: value });
                    setShowSpecialty(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowSpecialty(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showEmrExperience}
            onRequestClose={() => setShowEmrExperience(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select EMR or EHR Experience
                </Text>
                <CustomDropdown
                  options={optionsEMR}
                  selectedValue={"EMR or EHR Experience"}
                  onValueChange={(value) => handleAddEmrExperience(value)}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowEmrExperience(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showLicenseType}
            onRequestClose={() => setShowLicenseType(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select License Type
                </Text>
                <CustomDropdown
                  options={option}
                  selectedValue={"LicenseType"}
                  onValueChange={(value) => handleAddLicenseType(value)}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowLicenseType(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showBreak}
            onRequestClose={() => setShowBreak(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Break
                </Text>
                <CustomDropdown
                  options={optionBreak}
                  selectedValue={"Break"}
                  onValueChange={(value) => {
                    let date = new Date(jobState.endTime);
                    let dateEnd = new Date(jobState.endTime);
                    dateEnd.setFullYear(date.getFullYear());
                    dateEnd.setMonth(date.getMonth());
                    dateEnd.setDate(date.getDate());
                    dateEnd.setHours(
                      value === "1 Hour" ? date.getHours() + 1 : date.getHours()
                    );
                    dateEnd.setMinutes(
                      value === "15 Minutes"
                        ? date.getMinutes() + 15
                        : value === "30 Minutes"
                          ? date.getMinutes() + 30
                          : value === "45 Minutes"
                            ? date.getMinutes() + 45
                            : date.getMinutes()
                    );

                    setJobState({
                      ...jobState,
                      break: value,
                      endTime: dateEnd,
                    });
                    setShowBreak(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowBreak(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showTiming}
            onRequestClose={() => setShowTiming(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Time
                </Text>
                <CustomDropdown
                  options={timingCount}
                  selectedValue={"Time"}
                  onValueChange={(value) => {
                    setJobState({ ...jobState, jobTiming: value });
                    setShowTiming(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowTiming(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showExperienceLevel}
            onRequestClose={() => setShowExperienceLevel(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Experience Level
                </Text>
                <CustomDropdown
                  options={experienceLevel}
                  selectedValue={"Experience Level"}
                  onValueChange={(value) => {
                    setJobState({ ...jobState, yearOfExperience: value });
                    setShowExperienceLevel(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowExperienceLevel(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={patientView}
            onRequestClose={() => setPatientView(false)}
          >
            <View
              style={{
                height: height,
                // justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  marginTop: 100,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>
                  Customer Name
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <TextInput
                    style={{
                      width: "90%",
                      padding: 2,
                      paddingLeft: 5,
                      fontSize: 12,
                      color: "#737373",
                    }}
                    onChangeText={(text) => {
                      setPatientSearch(text);
                      filterSuggestions(text);
                    }}
                    value={patientSearch}
                    autoCapitalize="sentences"
                    keyboardType={"default"}
                    placeholderTextColor="#b3b3b3"
                    placeholder={"Search Customer Name"}
                  />
                  <View
                    style={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {patientSearch !== "" ? (
                      <TouchableOpacity
                        onPress={() => setPatientSearch("")}
                        style={{ marginLeft: 3 }}
                      >
                        <Ionicons name="close-sharp" size={22} color="#808080" />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>

                <FlatList
                  // style={styles.list}
                  data={
                    patientSearch.length === 0 ? patientData : filteredSuggestions
                  }
                  renderItem={renderSuggestion}
                  keyExtractor={(item) => item?.id}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setPatientView(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </View>}
    </KeyboardAvoidingView>
  );
};

export default EditJobScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  input: {
    color: "#000",
    paddingLeft: 15,
    fontWeight: "bold",
    height: 40,
    fontSize: 15,
    marginVertical: 10,
    borderRadius: 5,
  },
  homeBtn: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#00b359",
    width: "40%",
    height: 35,
    borderRadius: 5,
    marginVertical: 15,
    marginHorizontal: 5,
    alignSelf: "center",
  },
  dayHeaderText: {
    fontWeight: "bold",
  },
  dayContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    marginTop: 6,
  },
  pastDateContainer: {
    backgroundColor: "#CCCCCC",
  },
  futureDateText: {
    color: "black",
  },
  pastDateText: {
    color: "white",
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
  },
  dropdownItem: {
    padding: 8,
  },
});
