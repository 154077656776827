// import { CourierClient } from "@trycourier/courier";

// const courier = CourierClient({
//   authorizationToken: "pk_test_0BD69Z2GTGMH98JVCQQGTQRHVNYE",
// });

// export const sendNewPushNotification = async (data) => {
//   const { expoPushToken, job, typeMessage, screen } = data;

//   console.log("expoPushToken", expoPushToken);
//   const requestId = await courier.send({
//     message: {
//       to: {
//         expo: {
//           token: expoPushToken,
//         },
//       },
//       content: {
//         title: job,
//         body: typeMessage,
//       },
//       data: { screen: screen },
//     },
//   });
//   console.log(requestId);
// };

export const sendNewPushNotification = async (data) => {
  const { expoPushToken, job, typeMessage, screen } = data;
  console.log("expoPushToken", expoPushToken);
  const message = {
    to: expoPushToken,
    sound: "default",
    title: job,
    body: typeMessage,
    data: { screen: screen },
  };

  const response = await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  const responseData = await response.json();
  console.log("responseData", responseData);
};

// export const sendNewJobPushNotification = async (data) => {
//   const { expoPushToken, job, typeMessage, screen, jobIds } = data;

//   console.log("expoPushToken", expoPushToken);
//   const requestId = await courier.send({
//     message: {
//       to: {
//         expo: {
//           token: expoPushToken,
//         },
//       },
//       content: {
//         title: job,
//         body: typeMessage,
//       },
//       data: { jobIds: jobIds, screen: screen },
//     },
//   });
//   console.log(requestId);
// };

export const sendNewJobPushNotification = async (data) => {
  const { expoPushToken, job, typeMessage, screen, jobIds } = data;
  console.log("expoPushToken", expoPushToken);
  const message = {
    to: expoPushToken,
    sound: "default",
    title: job,
    body: typeMessage,
    data: { jobIds: jobIds, screen: screen },
  };

  const response = await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  const responseData = await response.json();
  console.log("responseData", responseData);
};

// export const sendPushNotification = async (data) => {
//   const { expoPushToken, job, typeMessage, jobId } = data;

//   console.log("expoPushToken", expoPushToken);
//   const requestId = await courier.send({
//     message: {
//       to: {
//         expo: {
//           token: expoPushToken,
//         },
//       },
//       content: {
//         title: job,
//         body: typeMessage,
//       },
//       data: { jobId: jobId, screen: "JobDetailScreen" },
//     },
//   });
//   console.log(requestId);
// };

export const sendPushNotification = async (data) => {
  const { expoPushToken, job, typeMessage, jobId } = data;
  console.log("expoPushToken", expoPushToken);
  const message = {
    to: expoPushToken,
    sound: "default",
    title: job,
    body: typeMessage,
    data: { jobId: jobId, screen: "JobDetailScreen" },
  };

  const response = await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  const responseData = await response.json();
  console.log("responseData", responseData);
};

// export const sendChatPushNotification = async (data) => {
//   const { expoPushToken, name, typeMessage, facilityId, nurseId } = data;

//   console.log("expoPushToken", expoPushToken);
//   const requestId = await courier.send({
//     message: {
//       to: {
//         expo: {
//           token: expoPushToken,
//         },
//       },
//       content: {
//         title: name,
//         body: typeMessage,
//       },
//       sound: "../assets/audio/mySoundFile.wav",
//       data: {
//         facilityId: facilityId,
//         nurseId: nurseId,
//         screen: "ChatRoomScreen",
//       },
//     },
//   });
//   console.log(requestId);
// };

export const sendChatPushNotification = async (data) => {
  const { expoPushToken, name, typeMessage, facilityId, nurseId } = data;
  console.log("expoPushToken", expoPushToken);
  const message = {
    to: expoPushToken,
    sound: "default",
    title: name,
    body: typeMessage,
    data: {
      facilityId: facilityId,
      nurseId: nurseId,
      screen: "ChatRoomScreen",
    },
  };

  const response = await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  const responseData = await response.json();
  console.log("responseData", responseData);
};

export const sendPushNotificationActivate = async (data) => {
  const { expoPushToken, job, typeMessage, jobId } = data;
  console.log("expoPushToken", expoPushToken);
  const message = {
    to: expoPushToken,
    sound: "default",
    title: job,
    body: typeMessage,
    data: { jobId: jobId, screen: "ManageUser" },
  };

  const response = await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  const responseData = await response.json();
  console.log("responseData", responseData);
};
