import moment from "moment";
import { Text, Linking } from "react-native";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getTimeDifference(start, end) {
  // Convert start time to minutes since midnight
  let startArr = start.split(" ");
  let startMinutes =
    parseInt(startArr[0].split(":")[0]) * 60 +
    parseInt(startArr[0].split(":")[1]);
  if (startArr[1] === "PM" && startArr[0].split(":")[0] !== "12") {
    startMinutes += 12 * 60;
  }

  // Convert end time to minutes since midnight
  let endArr = end.split(" ");
  let endMinutes =
    parseInt(endArr[0].split(":")[0]) * 60 + parseInt(endArr[0].split(":")[1]);
  if (endArr[1] === "PM" && endArr[0].split(":")[0] !== "12") {
    endMinutes += 12 * 60;
  }

  // Calculate difference in minutes
  let diffMinutes = endMinutes - startMinutes;
  if (diffMinutes < 0) {
    diffMinutes += 24 * 60;
  }

  // Convert to hours and round to two decimal places
  let diffHours = Math.round((diffMinutes / 60) * 100) / 100;

  return diffHours;
}

export function jobUniqueId(date, type) {
  const momentDate = moment(new Date(date));
  const hour = momentDate.hours();
  const minute = momentDate.minutes();
  const second = momentDate.seconds();
  const millisecond = momentDate.milliseconds();

  let jobUniqueId = `${type === "Shift" ? "SFT" : "VST"} - ${moment(
    date
  ).format("YYYYMMDD")}-${hour < 10 ? "0" + hour : hour}${minute < 10 ? "0" + minute : minute
    }${second < 10 ? "0" + second : second}${millisecond}`;

  return jobUniqueId;
}

export function jobUniqueIdDoc(date, type) {
  const momentDate = moment(new Date(date));
  const hour = momentDate.hours();
  const minute = momentDate.minutes();
  const second = momentDate.seconds();
  const millisecond = momentDate.milliseconds();

  let jobUniqueId = `${type === "Shift" ? "SFT" : "VST"}-${moment(date).format(
    "YYYYMMDD"
  )}-${hour < 10 ? "0" + hour : hour}${minute < 10 ? "0" + minute : minute}${second < 10 ? "0" + second : second
    }${millisecond}`;

  return jobUniqueId;
}

export function timeDifferent(sTime, eTime) {
  const startTime = moment(moment(new Date(sTime)).format("hh:mma"), "hh:mm A");

  const start = moment(new Date(sTime));
  const end = moment(new Date(eTime));

  const endTime = moment(moment(new Date(eTime)).format("hh:mma"), "hh:mm A");
  const endTimeEnd = moment(
    moment(new Date(eTime)).format("hh:mma"),
    "hh:mm A"
  ).add(1, "day");

  const duration = moment.duration(endTime.diff(startTime));
  const durationEnd = moment.duration(endTimeEnd.diff(startTime));

  const hours = Math.floor(duration.asHours());
  const hoursEnd = Math.floor(durationEnd.asHours());
  const minutes = Math.floor(duration.asMinutes()) - hours * 60;

  // const onlyHours = `${
  //   end.hour() <= 12 && start.hour() <= 12
  //     ? hours
  //     : end.hour() <= 12
  //     ? hoursEnd
  //     : hours
  // } ${
  //   end.hour() <= 12 && start.hour() <= 12
  //     ? hours <= 1
  //       ? "hour"
  //       : "hours"
  //     : end.hour() <= 12
  //     ? hoursEnd <= 1
  //       ? "hour"
  //       : "hours"
  //     : hours <= 1
  //     ? "hour"
  //     : "hours"
  // }`;

  const onlyHours = `${end.hour() <= 12 && start.hour() <= 12
    ? hours
    : end.hour() <= 12
      ? hoursEnd
      : hours
    }${hours <= 1 ? "hour" : "hours"} ${minutes <= 0 ? "" : `${minutes}minutes`}`;

  return onlyHours;
}

export function timeDifferentFun(sTime, eTime) {
  const startTime = moment(moment(new Date(sTime)).format("hh:mma"), "hh:mm A");

  const start = moment(new Date(sTime));
  const end = moment(new Date(eTime));

  const endTime = moment(moment(new Date(eTime)).format("hh:mma"), "hh:mm A");
  const endTimeEnd = moment(
    moment(new Date(eTime)).format("hh:mma"),
    "hh:mm A"
  ).add(1, "day");

  const duration = moment.duration(endTime.diff(startTime));
  const durationEnd = moment.duration(endTimeEnd.diff(startTime));

  const hours = Math.floor(duration.asHours());
  const hoursEnd = Math.floor(durationEnd.asHours());
  const minutes = Math.floor(duration.asMinutes()) - hours * 60;


  const onlyHours = `${end.hour() <= 12 && start.hour() <= 12
    ? hours
    : end.hour() <= 12
      ? hoursEnd
      : hours
    }`;

  return parseInt(onlyHours);
}

export function timeDifferentCard(sTime, eTime) {
  const startTime = moment(moment(new Date(sTime)).format("hh:mma"), "hh:mm A");

  const start = moment(new Date(sTime));
  const end = moment(new Date(eTime));

  const endTime = moment(moment(new Date(eTime)).format("hh:mma"), "hh:mm A");
  const endTimeEnd = moment(
    moment(new Date(eTime)).format("hh:mma"),
    "hh:mm A"
  ).add(1, "day");

  const duration = moment.duration(endTime.diff(startTime));
  const durationEnd = moment.duration(endTimeEnd.diff(startTime));

  const hours = Math.floor(duration.asHours());
  const hoursEnd = Math.floor(durationEnd.asHours());
  const minutes = Math.floor(duration.asMinutes()) - hours * 60;

  const onlyHours = `${end.hour() <= 12 && start.hour() <= 12
    ? hours
    : end.hour() <= 12
      ? hoursEnd
      : hours
    }${hours <= 1 ? "h" : "h"} ${minutes <= 0 ? "" : `${minutes}m`}`;

  return onlyHours;
}

export function totalAmount(sTime, eTime, baseRate) {
  const startTime = moment(moment(new Date(sTime)).format("hh:mma"), "hh:mm A");

  const start = moment(new Date(sTime));
  const end = moment(new Date(eTime));

  const endTime = moment(moment(new Date(eTime)).format("hh:mma"), "hh:mm A");
  const endTimeEnd = moment(
    moment(new Date(eTime)).format("hh:mma"),
    "hh:mm A"
  ).add(1, "day");

  const duration = moment.duration(endTime.diff(startTime));
  const durationEnd = moment.duration(endTimeEnd.diff(startTime));

  const hours = Math.floor(duration.asHours());
  const hoursEnd = Math.floor(durationEnd.asHours());
  // const minutes = Math.floor(duration.asMinutes()) - hours * 60;

  let durationInDecimal =
    end.hour() <= 12 && start.hour() <= 12
      ? hours
      : end.hour() <= 12
        ? hoursEnd
        : hours;
  let payment = durationInDecimal * baseRate;
  let paymentFormatted = payment.toFixed(2);
  let paymentInDollars = "$" + paymentFormatted.toString();

  return paymentInDollars;
}

export const openMap = async (from) => {
  const url = `https://maps.google.com/?q=${from}`;
  // const url = `https://maps.google.com/?saddr=${from},&daddr=${to}}`;
  const supported = await Linking.canOpenURL(url);
  if (supported) {
    await Linking.openURL(url);
  } else {
    throw new Error("Couldn't open the map.");
  }
};


// function convertDate(dateString) {
//   const date = new Date(dateString);
//   const formattedDate = date.toISOString().split("T")[0];

//   return formattedDate;
// }
export function convertDate(dateString) {
  const d = moment(dateString).format("YYYY-MM-DD");
  return d;
}

export function DateString(dateString) {
  const d = moment(dateString).format("MM-DD-YYYY");
  return (
    <Text>{d}</Text>
  );
}

export function DateFormat(dateString) {
  const d = moment(dateString).format("MMM DD");
  return d;
}

export function GetDateMergedTime(date, time) {
  const date1 = moment(date).format('YYYY-MM-DD')
  const time1 = moment(time).format('hh:mm a')
  const mergedTime = moment(`${date1} ${time1}`, `YYYY-MM-DD hh:mm a`);
  return mergedTime
}

export function getTimeInMinutes(timeString) {
  const [time, period] = timeString.split(" ");
  const [hours, minutes] = time.split(":");
  let hoursIn24Format = parseInt(hours, 10);

  if (period === "pm" && hoursIn24Format !== 12) {
    hoursIn24Format += 12;
  } else if (period === "am" && hoursIn24Format === 12) {
    hoursIn24Format = 0;
  }

  return hoursIn24Format * 60 + parseInt(minutes, 10);
}

// export function getDayName(value) {
//   const dateString = value;
//   const date = new Date(dateString);

//   // Getting the day of the week and month names for the output
//   const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   const monthNames = [
//     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
//     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//   ];

//   // Formatting the date to the desired format
//   const formattedDate = `${dayNames[date.getDay()]} ${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} 12:00:00 GMT-0500 (Central Daylight Time)`;

//   return formattedDate
// }

// export const formatTime = (date) => {
//   let hour = date?.getHours();
//   let minutes = date?.getMinutes();
//   let maxH = date?.getHours() - 12;

//   return (
//     <Text>
//       {hour === 0
//         ? "12"
//         : hour > 12
//           ? maxH < 10
//             ? "0" + maxH
//             : maxH
//           : hour < 10
//             ? "0" + hour
//             : hour}
//       :{minutes < 10 ? "0" + minutes : minutes} {hour > 12 ? "PM" : "AM"}
//     </Text>
//   );
// };

// export const formattedReturnTime = (value) => {
//   const date = new Date(value);

//   let hour = date?.getHours();
//   let minutes = date?.getMinutes();
//   let maxH = date?.getHours() - 12;

//   return `${hour === 0
//     ? "12"
//     : hour > 12
//       ? maxH < 10
//         ? "0" + maxH
//         : maxH
//       : hour < 10
//         ? "0" + hour
//         : hour
//     }:${minutes < 10 ? "0" + minutes : minutes} ${hour > 12 ? "PM" : "AM"}`;
// };

// export const formatDate = (da) => {
//   const date = new Date(da);
//   const formattedDate = `${date.getMonth() + 1
//     }-${date.getDate()}-${date.getFullYear()}`;

//   const parts = formattedDate.split("-");
//   const formatDate = `${parts[0].padStart(2, "0")}-${parts[1].padStart(
//     2,
//     "0"
//   )}-${parts[2]}`;
//   return formatDate;
// };
