import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  FlatList,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

const CustomStatusFilterDropDown = ({
  sliderWidth,
  color,
  onValueChange,
  value
}) => {

  const handleValueChange = (value) => {
    onValueChange(value);
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  const data = [
    { name: "Open", value: "Open" },
    { name: "Pending Assignment", value: "Pending Assignment" },
    { name: "Pending Approval", value: "Pending Approval" },
    { name: "Missed", value: "Missed" },
    { name: "Pending Clock Out", value: "Pending Clock Out" },
    { name: "Nurse Assigned", value: "Nurse Assigned" },
    { name: "In-Progress", value: "In-Progress" },
    { name: "Completed", value: "Completed" },
    { name: "Unfulfilled", value: "Unfulfilled" },
  ]

  return (
    <View style={{}}>
      <TouchableOpacity
        onPress={() => setOpen(open ? false : true)}
        style={{
          marginTop: 5,
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          elevation: 1,
          height: 40,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0.5,
          borderColor: "#e6e6e6",
          paddingHorizontal: 10,
          justifyContent: "space-between",
          marginHorizontal: sliderWidth
        }}>
        {
          <Text style={{ color: color }}>
            Status - {value}
          </Text>
        }

        <MaterialIcons
          name={"keyboard-arrow-down"}
          size={24}
          color="#808080"
        />
      </TouchableOpacity>
      {
        open &&
        <FlatList
          style={{
            height: 200,
            paddingHorizontal: 10
          }}
          data={data}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                key={index}
                style={styles.optionContainer}
                onPress={() => handleValueChange(item?.value)}
              >
                <Text style={styles.optionText}>{item?.name}</Text>
              </TouchableOpacity>
            );
          }}
          keyExtractor={(item, index) => index}
        />
      }
    </View >
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    borderRadius: 10,
    // width: "100%",
    // marginRight: 5,
    // paddingHorizontal: 10,
    // marginTop: 10,
  },
  selectedValueContainer: {
    height: 40,
    borderWidth: 1,
    borderColor: "#e6e6e6",
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  selectedValueText: {
    fontSize: 13,
    color: "#737373",
  },
  optionContainer: {
    height: 40,
    justifyContent: "center",
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  optionText: {
    fontSize: 12,
  },
});

export default CustomStatusFilterDropDown;
