import { API_URL } from "./config";

export async function getNurseDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getNurseUniqueIdDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/uniqueId/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getUserByEmail(authToken, email) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/by/email/${email}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getAllNurseList(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/all/location/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getManageNurseList(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/manager/manage/employee/all/nurse/list/location/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function updateDetails(authToken, id, data) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data), // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
}

export async function getNurseWeekAvailabilityForDirectAssignment(
  authToken,
  id,
  startDate,
  endDate,
  startTime,
  endTime,
  selectedWeeks
) {

  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    id: String(id),
    startDate: String(startDate),
    endDate: String(endDate),
    startTime: String(startTime),
    endTime: String(endTime),
    selectedWeeks: String(selectedWeeks),
  });

  const url = `${API_URL}/nurse/job/available/dates?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getJobMatchedAllNurse(
  authToken,
  organization,
  location,
  licenseType,
  fullAddress
) {

  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    org: String(organization),
    location: String(location),
    licenseType: String(licenseType),
    fullAddress: String(fullAddress),
  });

  const url = `${API_URL}/nurse/job/matched/nurse/list?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    // console.log(res)
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    // console.log(res)
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getSwapNurseAvailableList(
  authToken,
  org,
  location,
  startDate,
  endDate,
  startTime,
  endTime,
  licenseType,
  fullAddress
) {

  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    org: String(org),
    location: String(location),
    startDate: String(startDate),
    endDate: String(endDate),
    startTime: String(startTime),
    endTime: String(endTime),
    licenseType: String(licenseType),
    fullAddress: String(fullAddress),
  });

  const url = `${API_URL}/nurse/job/available_list?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getNurseAvaJobDate(
  authToken,
  id,
  startDate,
  endDate,
  startTime,
  endTime
) {

  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    id: String(id),
    startDate: String(startDate),
    endDate: String(endDate),
    startTime: String(startTime),
    endTime: String(endTime)
  });

  const url = `${API_URL}/nurse/job/available/nurse/date/detail?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getCalenderDataNurse(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/my/full/calender/view/data/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getAgendaDataNurse(authToken, id, date) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse/my/full/calender/view/${date}/details/:id${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}