import moment from "moment";
import { timeDifferentFun } from "../utils/function";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import { Alert, Linking, Platform } from "react-native";
import FileSaver from "file-saver";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";

export const registerForPushNotificationsAsync = async () => {
  let token;
  let res;

  if (Device.isDevice) {
    const response = await Notifications.getPermissionsAsync();
    res = response.status

    // token = (await Notifications.getExpoPushTokenAsync()).data;
    try {
      const { data: expoPushToken } = await Notifications.getExpoPushTokenAsync({
        experienceId: '@nursdhealth/NursdFlowDevAPI',
        // Add the following line with the projectId
        projectId: '15c04c40-a5d3-424c-99a0-96b9af8afbab',
      });

      console.log('Expo push token:', expoPushToken);
      token = expoPushToken
    } catch (error) {
      console.error('Error getting Expo push token:', error);
    }

    if (
      response.status === "denied" &&
      (token === undefined || token === null)
    ) {
      const response = await Notifications.getPermissionsAsync();

      if (response.status === "denied") {
        Alert.alert(
          "Permission Denied",
          "Please enable notifications from your device settings and restart the app.",
          [
            {
              text: "Cancel",
              style: "cancel",
            },
            {
              text: "Open Settings",
              onPress: () => {
                Linking.openSettings();
              },
            },
          ]
        );
      } else {
        Alert.alert("Success");
      }
    }
  } else {
    Alert.alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }
  // console.log(token);
  return { token, res };
}

export const generateOneWeekDateForJobPost = async (startDate) => {
  const dates = [];
  const currentDate = new Date(startDate);

  // Calculate the day of the week for the provided date (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const dayOfWeek = currentDate.getDay();

  // Calculate the date of the current Sunday (start of the week)
  const currentSunday = new Date(currentDate);
  currentSunday.setDate(currentDate.getDate() - dayOfWeek);

  // Generate dates for the entire week (Sunday to Saturday)
  for (let i = 0; i < 7; i++) {
    const nextDate = new Date(currentSunday);
    nextDate.setDate(currentSunday.getDate() + i);
    dates.push({
      date: moment(nextDate).format("YYYY-MM-DD"),
      day: moment(nextDate).format("ddd-DD"),
      dayOrder: "day" + (i + 1)
    });
  }

  dates.unshift({
    day: "Nurse List",
    dayOrder: "day0"
  });

  return dates;
};

export const getTableData = (nurseList, jobData, timeOffNurse, weekdays) => {
  const result = [];

  let totalData = jobData?.concat(timeOffNurse)

  nurseList.forEach(nurseItem => {
    const nurseId = nurseItem.id;
    const nurseData = {
      id: nurseItem,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
      day6: [],
      day7: [],
      totalHours: 0,
    };

    totalData?.forEach(dataItem => {
      if (dataItem?.jobFinalSelectionNurseId === nurseId || dataItem?.nurseTableID === nurseId) {
        const dateItem = weekdays?.find(date => date.date === moment(dataItem?.startDate).format("YYYY-MM-DD"));
        if (dateItem) {
          const dayKey = dateItem.dayOrder;
          nurseData[dayKey].push(dataItem);
          if (!dataItem?.timeOffReason) {
            const hoursWorked = timeDifferentFun(dataItem?.startTime, dataItem?.endTime);
            nurseData.totalHours += hoursWorked;
          }
        }
      }
    });
    result.push(nurseData);
  });
  //Nurse Sorting
  result.sort((a, b) =>
    a?.id?.firstName
      ?.toLowerCase()
      ?.localeCompare(b?.id?.firstName?.toLowerCase())
  )

  return result
};

export const convertToCSV = (jsonData) => {
  const separator = ",";
  const keys = Object.keys(jsonData[0]);
  const csvContent = [
    keys.join(separator),
    ...jsonData.map((item) => {
      // Extract the 'FullAddress' property
      const { FullAddress, ...rest } = item;

      // Join the values of the other properties with the 'FullAddress' value
      const values = keys.map((key) =>
        key === "FullAddress" ? `"${FullAddress}"` : rest[key]
      );

      return values.join(separator);
    }),
  ].join("\n");

  return csvContent;
}

export async function downloadCSV(csvData, type) {
  let current = moment().format("MM-DD-YYYY");

  if (Platform.OS === "web") {
    const blob = new Blob([csvData], { type: "text/csv" });
    const fileName = `VisitMyPayments-${current}.csv`;

    FileSaver.saveAs(blob, fileName);
  } else {
    const fileUri =
      FileSystem.documentDirectory + `${type}MyPayments-${current}.csv`;

    await FileSystem.writeAsStringAsync(fileUri, csvData);

    Sharing.shareAsync(fileUri, {
      mimeType: "text/csv",
      dialogTitle: "Download CSV",
    });
  }
}

export async function downloadAndOpenPDF(link) {
  if (Platform.OS === "web") {
    FileSaver.saveAs(link);
  } else {
    Linking.openURL(link);
  }
}

export const locationSeparator = (inputString) => {

  // Extract letters
  const letters = inputString.replace(/\d+/g, '');

  // Extract numbers
  const numbers = inputString.replace(/\D+/g, '');

  return letters + "," + numbers

}