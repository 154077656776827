import { View, StyleSheet, Text, ScrollView, Platform, Dimensions } from "react-native";
import React, { useState, useEffect } from "react";
import NewJobCard from "../../Manage/Card/NewJobCard";
import { TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ActivityIndicator } from "react-native";
import { deleteJob, getJobStatusList, getJobStatusListLocation } from "../../../api_url/jobTable";
import { getFacilityDetails } from "../../../api_url/facilityTable";
import { useAuth } from "../../../context/AuthContext";
import { ChatState } from "../../../context/ChatContext";
import CustomStatusFilterDropDown from "../../../customComponents/CustomStatusFilterDropDown";

const ManageJobVisit = (props) => {
  const screenFrom = props !== undefined && props?.route?.params?.data?.from;

  const { authToken, userId } = useAuth();
  const { fetchAgainJobList } = ChatState();

  const { height } = Dimensions.get("window")
  const [render, setRender] = useState(false);
  const [status, setStatus] = useState("Open");
  const [preStatus, setPreStatus] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBottom, setLoadingBottom] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [dateSort, setDateSort] = useState(null);

  useEffect(() => {
    if (userId !== undefined) {
      getFacilityJob(userId, 1, status);
    }
  }, [userId, fetchAgainJobList, status]);

  const getFacilityJob = async (id, page, stat) => {
    if (screenFrom === "Location") {
      try {
        const facility = await getFacilityDetails(authToken, id);
        const itemArr = await getJobStatusListLocation(authToken,
          facility?.location_id,
          facility?.organization,
          "Visit",
          stat,
          page,
          10);
        if (stat === preStatus) {
          const updatedData = [...data, ...itemArr?.data];
          setData(updatedData);
          setNextPage(itemArr?.nextPage);
          setPreviousPage(itemArr?.previousPage);
          setLoading(false);
          setLoadingBottom(false);
          setPreStatus(stat);
        } else {
          setData(itemArr?.data);
          setNextPage(itemArr?.nextPage);
          setPreviousPage(itemArr?.previousPage);
          setLoading(false);
          setLoadingBottom(false);
          setPreStatus(stat);
          setRender(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    } else {
      try {
        const itemArr = await getJobStatusList(authToken, id, "Visit", stat, page, 10);
        if (stat === preStatus) {
          const updatedData = [...data, ...itemArr?.data];
          setData(updatedData);
          setNextPage(itemArr?.nextPage);
          setPreviousPage(itemArr?.previousPage);
          setLoading(false);
          setLoadingBottom(false);
          setPreStatus(stat);
        } else {
          setData(itemArr?.data);
          setNextPage(itemArr?.nextPage);
          setPreviousPage(itemArr?.previousPage);
          setLoading(false);
          setLoadingBottom(false);
          setPreStatus(stat);
          setRender(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    }
  };

  const onJobDetailNavigate = (d) => {
    props.navigation.navigate("JobDetailsScreen", {
      data: d,
    });
  };

  if (dateSort) {
    data?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  } else if (dateSort === false) {
    data?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  }

  const handleScrollEnd = async () => {
    if (nextPage !== null) {
      setLoadingBottom(true);
      await getFacilityJob(userId, nextPage, status)
    }
  };

  const handleScroll = async (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    // console.log(scrollTop, scrollHeight, clientHeight)
    // Check if user has scrolled to the top 
    if (Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)) {
      // Load more messages
      if (nextPage !== null) {
        await getFacilityJob(userId, nextPage, status)
      }
    }
  };

  const deleteJobAction = async (id) => {
    await deleteJob(authToken, id)
      .then(
        async res => {
          setLoading(true);
          setFetchAgainJobDashboard(!fetchAgainJobDashboard);
          props.navigation.goBack()
        }
      ).catch(e => console.log(e))
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <View>
          {<View
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginHorizontal: 20,
                marginTop: 5,
              }}
              onPress={() => setDateSort(!dateSort)}
              activeOpacity={0.5}
            >
              <CustomStatusFilterDropDown
                sliderWidth={0}
                color={"#595959"}
                onValueChange={(e) => {
                  setStatus(e);
                  setRender(true);
                }}
                value={status}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginHorizontal: 20,
                marginTop: 5,
              }}
              onPress={() => setDateSort(!dateSort)}
              activeOpacity={0.5}
            >
              <Text>Date Sort</Text>

              <MaterialCommunityIcons name="sort" size={22} color="#595959" />
            </TouchableOpacity>
          </View>}
          {
            Platform.OS === "web" ?
              <div
                style={{
                  overflowY: "auto",
                  transition: "scroll 1s ease-in-out",
                  maxHeight: height - 125,
                  marginBottom: 50,
                }}
                onScroll={handleScroll}
              >
                <View
                  style={{
                    marginBottom: 50,
                  }}>
                  {data?.length === 0 ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <Text>No Jobs</Text>
                    </View>
                  ) : data
                    ?.filter(
                      (element, index, self) =>
                        self.findIndex((e) => e.id === element.id) === index
                    )
                    ?.map((element, index) => {
                      return (
                        <NewJobCard
                          key={index}
                          element={element}
                          onJobDetailNavigate={onJobDetailNavigate}
                          userId={userId}
                          deleteJobAction={deleteJobAction}
                        />
                      );
                    })}
                </View>
              </div>
              :
              <ScrollView
                showsVerticalScrollIndicator={false}
                onScrollEndDrag={handleScrollEnd}
              >
                {data?.length === 0 ? (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <Text>No Jobs</Text>
                  </View>
                ) : (
                  render ?
                    <View
                      style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
                    >
                      <Text>Loading...</Text>
                    </View>
                    :
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: Platform.OS === "ios" ? 50 : 70,
                      }}
                    >
                      {data
                        ?.filter(
                          (element, index, self) =>
                            self.findIndex((e) => e.id === element.id) === index
                        )
                        ?.map((element, index) => {
                          return (
                            <NewJobCard
                              key={index}
                              element={element}
                              onJobDetailNavigate={onJobDetailNavigate}
                              userId={userId}
                              deleteJobAction={deleteJobAction}
                            />
                          );
                        })}
                      {loadingBottom && (
                        <ActivityIndicator
                          style={{ marginTop: 10 }}
                          size={30}
                          color={"#888"}
                        />
                      )}
                    </View>
                )}
              </ScrollView>

          }
        </View>
      )}
    </View>
  );
};

export default ManageJobVisit;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
