import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Dimensions,
  Alert,
  Platform,
} from "react-native";
import React from "react";

const { width, height } = Dimensions.get("window");
const SCREEN_WIDTH = width < height ? width : height;

const UserJobCard = ({
  element,
  jobFinalSelectionNurseId,
  setJobFinalSelectionNurseId,
}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() =>
        element?.id?.availableTime
          ? jobFinalSelectionNurseId === element?.id?.id
            ? setJobFinalSelectionNurseId("")
            : setJobFinalSelectionNurseId(element?.id?.id)
          : Platform.OS === "web"
            ? alert("Nurse is not available")
            : Alert.alert("Nurse is not available")
      }
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          backgroundColor:
            jobFinalSelectionNurseId === element?.id?.id
              ? "#e6ffe6"
              : element?.id?.availableTime
                ? "#fff"
                : "#ffcccc",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderWidth: 1,
            borderRadius: 10,
            borderColor: "#2775BD",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            width: 40,
            height: 40,
            overflow: "hidden",
          }}
        >
          {element?.id?.profile ? (
            <Image
              source={{
                uri: element?.id?.profile
              }}
              style={{
                width: 38,
                height: 38,
                borderRadius: 10,
              }}
               
            />
          ) : (
            <Image
              source={require("../../../assets/images/icon.jpg")}
              style={{ width: 80, height: 80 }}
            />
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <View
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: 12 }}>{element?.id?.name}</Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 3
              }}
            >
              <Text style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 10 }}>
                {element?.id?.license}
              </Text>
              {<Text style={{
                color: element?.totalHours < 40 ? "green" : "red",
                fontWeight: "700", fontSize: 10, marginLeft: 5
              }}>
                - {element?.totalHours}h
              </Text>}
            </View>

          </View>
          <View
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: element?.id?.availableTime ? "green" : "red",
                fontWeight:
                  jobFinalSelectionNurseId === element?.id?.id ? "800" : "700",
              }}
            >
              {jobFinalSelectionNurseId === element?.id?.id
                ? "Selected"
                : element?.id?.availableTime
                  ? "Available"
                  : "Not Available"}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderStyle: "dashed",
          borderColor: "lightgray",
          marginHorizontal: 5,
        }}
      />
    </TouchableOpacity>
  );
};

export default UserJobCard;
