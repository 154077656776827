import React, { useEffect, useState } from "react";
import { Text, View, StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AppView from "./src/navigation/appView";
import AppAuthContainer from "./src/navigation/authnavigation";
import { useNetInfo } from "@react-native-community/netinfo";
import { Feather } from "@expo/vector-icons";
import "@azure/core-asynciterator-polyfill";
import { useAuth, AuthContextProvider } from "./src/context/AuthContext";
import { getFacilityDetails } from "./src/api_url/facilityTable";
import { socket } from "./src/api_url/socket";

function App() {
  const { authToken, userId, userUniqueId, removeAuthToken } = useAuth();

  const [user, setUser] = useState(undefined);
  const [loadingScreen, setLoadingScreen] = useState(true);

  const netInfo = useNetInfo();

  useEffect(() => {
    setUser(undefined);
    if (authToken && userId) {
      getFacilityDetails(authToken, userId)
        .then((res) => {
          setUser(res);
          setLoadingScreen(false);
          socket?.emit("setup", res);
          socket?.on("connected", () => console.log(""));
        })
        .catch((e) => {
          if (e) {
            setUser(null);
            setLoadingScreen(false)
            removeAuthToken();
          }
        });
    } else {
      setLoadingScreen(false)
    }
  }, [authToken, removeAuthToken, userId]);

  return (
    <SafeAreaProvider>
      <StatusBar barStyle="default" />
      {netInfo.isConnected ? (
        <>
          {loadingScreen ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                backgroundColor: "#fff",
                alignItems: "center",
              }}
            >
              <Text>Loading...</Text>
            </View>
          ) : user !== undefined && user !== null ? (
            <AppView />
          ) : (
            <AppAuthContainer />
          )}
        </>
      ) : (
        <View
          style={{
            flex: 1,
            backgroundColor: "#fff",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Feather name="wifi-off" size={24} color="black" />
          <Text>Your are Offline</Text>
          <Text>check your connection</Text>
        </View>
      )}
    </SafeAreaProvider>
  );
}

export default function AppWithAuthProvider() {
  return (
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  );
}