import { View, StyleSheet, Text, ScrollView, Platform, Dimensions } from "react-native";
import React, { useState, useEffect } from "react";
import NewJobCard from "../Manage/Card/NewJobCard";
import { TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ActivityIndicator } from "react-native";
import { getJobStatusList, getJobStatusListLocation } from "../../api_url/jobTable";
import { getFacilityDetails } from "../../api_url/facilityTable";
import { useAuth } from "../../context/AuthContext";
import { ChatState } from "../../context/ChatContext";

const AcceptedJobs = (props) => {
  const screenFrom = props !== undefined && props?.route?.params?.data?.from;

  const { authToken, userId } = useAuth();
  const { fetchAgainJobList } = ChatState();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBottom, setLoadingBottom] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [dateSort, setDateSort] = useState(null);

  useEffect(() => {
    if (userId !== undefined) {
      getFacilityJob(userId, 1);
    }
  }, [userId, fetchAgainJobList]);

  const getFacilityJob = async (id, page) => {
    if (screenFrom === "Location") {
      try {
        const facility = await getFacilityDetails(authToken, id);
        const itemArr = await getJobStatusListLocation(authToken,
          facility?.location_id,
          facility?.organization,
          "Shift",
          "Nurse Assigned",
          page,
          10);
        if (page === 1) {
          setData(itemArr?.data);
        } else {
          const updatedData = [...data, ...itemArr?.data];
          setData(updatedData);
        }
        setNextPage(itemArr?.nextPage);
        setPreviousPage(itemArr?.previousPage);
        setLoading(false);
        setLoadingBottom(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    } else {
      try {
        const itemArr = await getJobStatusList(authToken, id, "Shift", "Nurse Assigned", page, 10);
        if (page === 1) {
          setData(itemArr?.data);
        } else {
          const updatedData = [...data, ...itemArr?.data];
          setData(updatedData);
        }
        setNextPage(itemArr?.nextPage);
        setPreviousPage(itemArr?.previousPage);
        setLoading(false);
        setLoadingBottom(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    }
  };

  const onJobDetailNavigate = (d) => {
    props.navigation.navigate("JobDetailsScreen", {
      data: d,
    });
  };

  if (dateSort) {
    data?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  } else if (dateSort === false) {
    data?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  }

  const handleScrollEnd = async () => {
    if (nextPage !== null) {
      setLoadingBottom(true);
      await getFacilityJob(userId, nextPage)
    }
  };

  const { height } = Dimensions.get("window")
  const handleScroll = async (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    // console.log(scrollTop, scrollHeight, clientHeight)
    // Check if user has scrolled to the top 
    if (Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)) {
      // Load more messages
      if (nextPage !== null) {
        await getFacilityJob(userId, nextPage)
      }
    }
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        Platform.OS === "web" ?
          <div
            style={{
              overflowY: "auto",
              transition: "scroll 1s ease-in-out",
              maxHeight: height - 125,
            }}
            onScroll={handleScroll}
          >
            <View
              style={{
                marginBottom: 50,
              }}>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginHorizontal: 20,
                  marginTop: 5,
                }}
                onPress={() => setDateSort(!dateSort)}
                activeOpacity={0.5}
              >
                <Text>Date Sort</Text>

                <MaterialCommunityIcons name="sort" size={22} color="#595959" />
              </TouchableOpacity>

              {data?.length === 0 ? (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Text>No Jobs</Text>
                </View>
              ) : data
                ?.filter(
                  (element, index, self) =>
                    self.findIndex((e) => e.id === element.id) === index
                )
                ?.map((element, index) => {
                  return (
                    <NewJobCard
                      key={index}
                      element={element}
                      onJobDetailNavigate={onJobDetailNavigate}
                      userId={userId}
                    />
                  );
                })}
            </View>
          </div>
          : <ScrollView
            showsVerticalScrollIndicator={false}
            onScrollEndDrag={handleScrollEnd}
          >
            {data?.length === 0 ? (
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Text>No Jobs</Text>
              </View>
            ) : (
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: Platform.OS === "ios" ? 30 : 50,
                }}
              >
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginHorizontal: 20,
                    marginTop: 5,
                  }}
                  onPress={() => setDateSort(!dateSort)}
                  activeOpacity={0.5}
                >
                  <Text>Date Sort</Text>

                  <MaterialCommunityIcons name="sort" size={22} color="#595959" />
                </TouchableOpacity>
                {data
                  ?.filter(
                    (element, index, self) =>
                      self.findIndex((e) => e.id === element.id) === index
                  )
                  ?.map((element, index) => {
                    return (
                      <NewJobCard
                        key={index}
                        element={element}
                        onJobDetailNavigate={onJobDetailNavigate}
                        userId={userId}
                      />
                    );
                  })}
                {loadingBottom && (
                  <ActivityIndicator
                    style={{ marginTop: 10 }}
                    size={30}
                    color={"#888"}
                  />
                )}
              </View>
            )}
          </ScrollView>
      )}
    </View>
  );
};

export default AcceptedJobs;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
